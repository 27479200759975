/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Ultima - Layout */
@import 'libs/core/src/lib/styles/tsm-theme/layout/styles/layout/layout';

/* DTL Custom Overrides [Ultima - Layout] */
@import 'libs/core/src/lib/styles/tsm-theme/override/layout/styles/layout/layout';

/* DTL Custom Icons */
@import 'libs/core/src/lib/styles/tsm-theme/icons/icons';

/* PrimeNG */
@import '~primeng/resources/primeng.min.css';
@import '~primeflex/primeflex.css';
@import '~primeicons/primeicons.css';

/* Print Option */
@import "libs/core/src/lib/styles/tsm-theme/override/layout/styles/layout/print";

/* AngularCDK*/
@import '@angular/cdk/overlay-prebuilt.css';

///* Quill Text Editor for p-editor */
//@import "~quill/dist/quill.core.css";
//@import "~quill/dist/quill.snow.css";

// Customer custom styles
@import 'assets/theme/customer-styles';
