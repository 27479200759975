// Inter Font Import
@import 'inter-ui/inter.css';

html {
  font-size: $scale;
  scroll-behavior: smooth;
}

// Update message wrapper
.update-wrapper {
  background-color: var(--yellow-200); //#fff3cd;
  background-image: repeating-linear-gradient(
    45deg,
    rgb(247 167 68 / 20%) 0,
    rgb(247 167 68 / 20%) 1px,
    #f7f1e5 0,
    #f7f1e5 50%
  );
  background-size: 10px 10px;
  border: 1px solid #ffecb5;
  border-radius: 5px;
  color: var(--yellow-800); //#664d03;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0.5em;
  padding: 0.5em;
  text-align: center;
  width: calc(100% - 1rem);
  z-index: 20000;

  .p-button {
    margin-right: 5px;
    margin-left: 5px;
  }

  .update-timer {
    display: inline-block;
    text-align: right;
    padding-right: 3px;
    width: 33px;
  }
}
