@font-face {
  font-family: 'tsm-icons';
  src: url('./tsm-icons.eot');
  src:
    url('./tsm-icons.eot?#iefix') format('embedded-opentype'),
    url('./tsm-icons.woff') format('woff'),
    url('./tsm-icons.ttf') format('truetype'),
    url('./tsm-icons.svg#tsm-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before,
[class^='tsm-icon-'],
[class*=' tsm-icon-'] {
  font-family: 'tsm-icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem; /* Preferred icon size */
  display: inline-block;
  //width: 1em;
  //height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

[data-icon]:before {
  content: attr(data-icon);
}

@import 'tsm-icons_map';
@import 'mixins';
