.layout-topbar {
  &::after {
    background: linear-gradient(
      to right,
      rgb(var(--primary-color-rgb) / 35%) 0%,
      rgb(var(--primary-color-rgb) / 20%) 33%,
      transparent 100%
    );
    background-size: 30% 100%;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
  }
}
