$scale: 14px !default;
$breakpoint: 992px !default;

//@import './_fonts';
@import './_utils';
@import './_typography';
@import './_animation';
@import './topbar/_topbar';
@import './sidebar/_sidebar';
@import './_menuprofile';
//@import './_megamenu';
//@import './_landing';
@import './_main';
@import './_content';
//@import './_footer';
//@import './_config';
//@import './_breadcrumb';
@import './_responsive';
