.layout-topbar {
  background-color: var(--topbar-bg);
  box-shadow: var(--topbar-box-shadow);
  height: 3.5rem;

  .layout-topbar-start {
    background: none;
    justify-content: flex-start;
    padding-left: 1rem;
    width: 238px;

    .menu-toggle-button {
      margin-right: 1rem;
      min-width: auto;
      padding: 0;
    }

    svg.menu-button-icon {
      height: 29px;
      width: 29px;

      &.menu-opened {
        display: block;
      }

      &.menu-closed {
        display: none;
      }
    }

    .layout-topbar-logo {
      margin-top: 1px;
    }
  }

  .layout-topbar-end {
    padding: 0 1rem 0 1rem;
    min-width: 0;

    .layout-topbar-actions-end {
      flex: 0;
      padding-left: 2rem;
    }
  }
}
