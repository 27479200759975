.tsm-icon-contract:before {
  content: '\62';
}
.tsm-icon-chip:before {
  content: '\63';
}
.tsm-icon-client:before {
  content: '\64';
}
.tsm-icon-cog-wheels:before {
  content: '\65';
}
.tsm-icon-collaboration:before {
  content: '\66';
}
.tsm-icon-lead-source:before {
  content: '\67';
}
.tsm-icon-compare:before {
  content: '\68';
}
.tsm-icon-control-panel:before {
  content: '\69';
}
.tsm-icon-crm:before {
  content: '\6a';
}
.tsm-icon-person:before {
  content: '\6b';
}
.tsm-icon-customer-type:before {
  content: '\6c';
}
.tsm-icon-configuration:before {
  content: '\6d';
}
.tsm-icon-design:before {
  content: '\6e';
}
.tsm-icon-network-inventory:before {
  content: '\6f';
}
.tsm-icon-gears:before {
  content: '\70';
}
.tsm-icon-user-group:before {
  content: '\71';
}
.tsm-icon-home:before {
  content: '\72';
}
.tsm-icon-service-management:before {
  content: '\73';
}
.tsm-icon-legal-form:before {
  content: '\74';
}
.tsm-icon-management:before {
  content: '\75';
}
.tsm-icon-management-1:before {
  content: '\76';
}
.tsm-icon-manage-codebooks:before {
  content: '\77';
}
.tsm-icon-handshake:before {
  content: '\78';
}
.tsm-icon-reminder:before {
  content: '\79';
}
.tsm-icon-custom-modules:before {
  content: '\7a';
}
.tsm-icon-role:before {
  content: '\41';
}
.tsm-icon-lead-rating:before {
  content: '\42';
}
.tsm-icon-setting-cloud:before {
  content: '\43';
}
.tsm-icon-setting-folder:before {
  content: '\44';
}
.tsm-icon-settings:before {
  content: '\45';
}
.tsm-icon-shape:before {
  content: '\46';
}
.tsm-icon-statistics:before {
  content: '\47';
}
.tsm-icon-ticketing:before {
  content: '\48';
}
.tsm-icon-tools:before {
  content: '\49';
}
.tsm-icon-user-management:before {
  content: '\4a';
}
.tsm-icon-user:before {
  content: '\4b';
}
.tsm-icon-vector:before {
  content: '\4c';
}
.tsm-icon-vector-2:before {
  content: '\4d';
}
.tsm-icon-config-form:before {
  content: '\4e';
}
.tsm-icon-work-force:before {
  content: '\4f';
}
.tsm-icon-hierarchical-structure:before {
  content: '\50';
}
.tsm-icon-balance:before {
  content: '\51';
}
.tsm-icon-management-3:before {
  content: '\52';
}
.tsm-icon-mobility:before {
  content: '\53';
}
.tsm-icon-activity:before {
  content: '\54';
}
.tsm-icon-solutions:before {
  content: '\55';
}
.tsm-icon-steps:before {
  content: '\56';
}
.tsm-icon-3rd-party-modules:before {
  content: '\57';
}
.tsm-icon-ticket:before {
  content: '\58';
}
.tsm-icon-contract-1:before {
  content: '\59';
}
.tsm-icon-catalog-management:before {
  content: '\5a';
}
.tsm-icon-catalog:before {
  content: '\30';
}
.tsm-icon-data-transfer:before {
  content: '\31';
}
.tsm-icon-exchange:before {
  content: '\32';
}
.tsm-icon-design-1:before {
  content: '\33';
}
.tsm-icon-characteristics:before {
  content: '\34';
}
.tsm-icon-entity-specifications:before {
  content: '\35';
}
.tsm-icon-registers:before {
  content: '\36';
}
.tsm-icon-form:before {
  content: '\37';
}
.tsm-icon-diagram:before {
  content: '\38';
}
.tsm-icon-terminal:before {
  content: '\39';
}
.tsm-icon-planner:before {
  content: '\21';
}
.tsm-icon-time-management:before {
  content: '\22';
}
.tsm-icon-time:before {
  content: '\23';
}
.tsm-icon-work-time:before {
  content: '\24';
}
.tsm-icon-worker:before {
  content: '\25';
}
.tsm-icon-checklist:before {
  content: '\26';
}
.tsm-icon-ordering:before {
  content: '\27';
}
.tsm-icon-add:before {
  content: '\28';
}
.tsm-icon-coding:before {
  content: '\29';
}
.tsm-icon-termed:before {
  content: '\2a';
}
.tsm-icon-time-management-1:before {
  content: '\2b';
}
.tsm-icon-teamwork:before {
  content: '\2c';
}
.tsm-icon-roles:before {
  content: '\2d';
}
.tsm-icon-work:before {
  content: '\2e';
}
.tsm-icon-couch:before {
  content: '\2f';
}
.tsm-icon-swing:before {
  content: '\3a';
}
.tsm-icon-call:before {
  content: '\3b';
}
.tsm-icon-group:before {
  content: '\3c';
}
.tsm-icon-message:before {
  content: '\3d';
}
.tsm-icon-briefcase:before {
  content: '\3e';
}
.tsm-icon-event:before {
  content: '\3f';
}
.tsm-icon-email:before {
  content: '\40';
}
.tsm-icon-envelope:before {
  content: '\5b';
}
.tsm-icon-script:before {
  content: '\5d';
}
.tsm-icon-park:before {
  content: '\5e';
}
.tsm-icon-call-1:before {
  content: '\5f';
}
.tsm-icon-script-1:before {
  content: '\60';
}
.tsm-icon-script-2:before {
  content: '\7b';
}
.tsm-icon-script-3:before {
  content: '\7c';
}
.tsm-icon-paint-palette:before {
  content: '\7d';
}
.tsm-icon-paint-brushes:before {
  content: '\7e';
}
.tsm-icon-profiles:before {
  content: '\5c';
}
.tsm-icon-innovation:before {
  content: '\e000';
}
.tsm-icon-paint:before {
  content: '\e001';
}
.tsm-icon-layout:before {
  content: '\e002';
}
.tsm-icon-flag:before {
  content: '\e003';
}
.tsm-icon-location:before {
  content: '\e004';
}
.tsm-icon-layout-1:before {
  content: '\e005';
}
.tsm-icon-meeting:before {
  content: '\e006';
}
.tsm-icon-user-1:before {
  content: '\e007';
}
.tsm-icon-user-group-1:before {
  content: '\e008';
}
.tsm-icon-user-2:before {
  content: '\e009';
}
.tsm-icon-user-group-2:before {
  content: '\e00a';
}
.tsm-icon-register:before {
  content: '\e00b';
}
.tsm-icon-user-3:before {
  content: '\e00c';
}
.tsm-icon-user-group-3:before {
  content: '\e00d';
}
.tsm-icon-workspace:before {
  content: '\e00e';
}
.tsm-icon-my-tasks:before {
  content: '\e00f';
}
.tsm-icon-customer-time:before {
  content: '\e010';
}
.tsm-icon-work-time-2:before {
  content: '\e011';
}
.tsm-icon-work-time-3:before {
  content: '\e012';
}
.tsm-icon-stock-movement:before {
  content: '\e013';
}
.tsm-icon-stock-item:before {
  content: '\e014';
}
.tsm-icon-stock-1:before {
  content: '\e015';
}
.tsm-icon-change-management:before {
  content: '\e016';
}
.tsm-icon-change-request:before {
  content: '\e017';
}
.tsm-icon-stock-movement-creation:before {
  content: '\e018';
}
.tsm-icon-pushpin:before {
  content: '\e019';
}
.tsm-icon-pin:before {
  content: '\e01a';
}
.tsm-icon-excel-file:before {
  content: '\e01b';
}
.tsm-icon-download:before {
  content: '\e01c';
}
.tsm-icon-excel:before {
  content: '\e01d';
}
.tsm-icon-data-analysis:before {
  content: '\e01e';
}
.tsm-icon-spreadsheet:before {
  content: '\e01f';
}
.tsm-icon-filter-1:before {
  content: '\e020';
}
.tsm-icon-trash:before {
  content: '\e021';
}
.tsm-icon-columns:before {
  content: '\e022';
}
.tsm-icon-approved:before {
  content: '\e023';
}
.tsm-icon-clean:before {
  content: '\e024';
}
.tsm-icon-erase:before {
  content: '\e025';
}
.tsm-icon-report:before {
  content: '\e026';
}
.tsm-icon-analysis:before {
  content: '\e027';
}
.tsm-icon-trash-1:before {
  content: '\e028';
}
.tsm-icon-move:before {
  content: '\e029';
}
.tsm-icon-home-house:before {
  content: '\e02a';
}
.tsm-icon-clock-streamline-time:before {
  content: '\e02b';
}
.tsm-icon-statistics-2:before {
  content: '\e02c';
}
.tsm-icon-clock:before {
  content: '\e02d';
}
.tsm-icon-duration:before {
  content: '\e02e';
}
.tsm-icon-find-user:before {
  content: '\e02f';
}
.tsm-icon-hourglass:before {
  content: '\e030';
}
.tsm-icon-future:before {
  content: '\e031';
}
.tsm-icon-target-user:before {
  content: '\e032';
}
.tsm-icon-search:before {
  content: '\e033';
}
.tsm-icon-stopwatch:before {
  content: '\e034';
}
.tsm-icon-customer-review:before {
  content: '\e035';
}
.tsm-icon-lead:before {
  content: '\e036';
}
.tsm-icon-default-checked:before {
  content: '\e037';
}
.tsm-icon-infinity:before {
  content: '\e038';
}
.tsm-icon-api:before {
  content: '\e039';
}
.tsm-icon-module:before {
  content: '\e03a';
}
.tsm-icon-puzzle-piece:before {
  content: '\e03b';
}
.tsm-icon-form-position:before {
  content: '\e03c';
}
.tsm-icon-form-1:before {
  content: '\e03d';
}
.tsm-icon-form-2:before {
  content: '\e03e';
}
.tsm-icon-check-mark:before {
  content: '\e03f';
}
.tsm-icon-targeting:before {
  content: '\e040';
}
.tsm-icon-puzzle-piece-1:before {
  content: '\e041';
}
.tsm-icon-crosshair-2:before {
  content: '\e042';
}
.tsm-icon-crosshair-4:before {
  content: '\e043';
}
.tsm-icon-crosshair-5:before {
  content: '\e044';
}
.tsm-icon-crosshair-7:before {
  content: '\e045';
}
.tsm-icon-refresh-data:before {
  content: '\e046';
}
.tsm-icon-ruler:before {
  content: '\e047';
}
.tsm-icon-tree:before {
  content: '\e048';
}
.tsm-icon-table:before {
  content: '\e049';
}
.tsm-icon-002-tools:before {
  content: '\e04a';
}
.tsm-icon-sketch:before {
  content: '\e04b';
}
.tsm-icon-palette:before {
  content: '\e04c';
}
.tsm-icon-development:before {
  content: '\e04d';
}
.tsm-icon-vector-1:before {
  content: '\e04e';
}
.tsm-icon-flowchart:before {
  content: '\e04f';
}
.tsm-icon-translation:before {
  content: '\e050';
}
.tsm-icon-registers-1:before {
  content: '\e051';
}
.tsm-icon-map:before {
  content: '\e052';
}
.tsm-icon-reflect:before {
  content: '\e053';
}
.tsm-icon-paint-roller:before {
  content: '\e054';
}
.tsm-icon-transform:before {
  content: '\e055';
}
.tsm-icon-tools-1:before {
  content: '\e056';
}
.tsm-icon-paint-palette-1:before {
  content: '\e057';
}
.tsm-icon-paint-brush:before {
  content: '\e058';
}
.tsm-icon-paint-spray:before {
  content: '\e059';
}
.tsm-icon-sketch-1:before {
  content: '\e05a';
}
.tsm-icon-menu-item:before {
  content: '\e05b';
}
.tsm-icon-align:before {
  content: '\e05c';
}
.tsm-icon-maintenance-plan:before {
  content: '\e05d';
}
.tsm-icon-bulk-edit:before {
  content: '\e05e';
}
.tsm-icon-users-skills:before {
  content: '\e05f';
}
.tsm-icon-edit-columns:before {
  content: '\e060';
}
.tsm-icon-edit-columns-1:before {
  content: '\e061';
}
.tsm-icon-upload:before {
  content: '\e062';
}
.tsm-icon-address-management:before {
  content: '\e063';
}
.tsm-icon-regions:before {
  content: '\e064';
}
.tsm-icon-shopping-cart:before {
  content: '\e065';
}
.tsm-icon-shopping-cart-add:before {
  content: '\e066';
}
.tsm-icon-shopping-cart-remove:before {
  content: '\e067';
}
.tsm-icon-empty:before {
  content: '\e068';
}
.tsm-icon-logging:before {
  content: '\e069';
}
.tsm-icon-calendar:before {
  content: '\e06a';
}
.tsm-icon-reload:before {
  content: '\e06b';
}
.tsm-icon-return:before {
  content: '\e06c';
}
.tsm-icon-reload-1:before {
  content: '\e06d';
}
.tsm-icon-reset-settings:before {
  content: '\e06e';
}
.tsm-icon-repeat:before {
  content: '\e06f';
}
.tsm-icon-repeat-1:before {
  content: '\e070';
}
.tsm-icon-save:before {
  content: '\e071';
}
.tsm-icon-comment-1:before {
  content: '\e072';
}
.tsm-icon-chat:before {
  content: '\e073';
}
.tsm-icon-notification:before {
  content: '\e074';
}
.tsm-icon-notification-1:before {
  content: '\e075';
}
.tsm-icon-sedan:before {
  content: '\e076';
}
.tsm-icon-cabriolet:before {
  content: '\e077';
}
.tsm-icon-autonomous-car:before {
  content: '\e078';
}
.tsm-icon-gift:before {
  content: '\e079';
}
.tsm-icon-discount:before {
  content: '\e07a';
}
.tsm-icon-history:before {
  content: '\e07b';
}
.tsm-icon-transaction-history:before {
  content: '\e07c';
}
.tsm-icon-file-pdf:before {
  content: '\e07d';
}
.tsm-icon-file-xls:before {
  content: '\e07e';
}
.tsm-icon-file-html:before {
  content: '\e07f';
}
.tsm-icon-file-doc:before {
  content: '\e080';
}
.tsm-icon-catalog-1:before {
  content: '\e081';
}
.tsm-icon-asterisk:before {
  content: '\e082';
}
.tsm-icon-provisioning:before {
  content: '\e083';
}
.tsm-icon-process:before {
  content: '\e084';
}
.tsm-icon-inventory:before {
  content: '\e085';
}
.tsm-icon-stock:before {
  content: '\e086';
}
.tsm-icon-table-1:before {
  content: '\e087';
}
.tsm-icon-tree-1:before {
  content: '\e088';
}
.tsm-icon-tree-2:before {
  content: '\e089';
}
.tsm-icon-refresh:before {
  content: '\e08a';
}
.tsm-icon-segment:before {
  content: '\e08b';
}
.tsm-icon-segment-1:before {
  content: '\e08c';
}
.tsm-icon-inventory-search:before {
  content: '\e08d';
}
.tsm-icon-signature-1:before {
  content: '\e08e';
}
.tsm-icon-signature:before {
  content: '\e08f';
}
.tsm-icon-router:before {
  content: '\e090';
}
.tsm-icon-search-location:before {
  content: '\e091';
}
.tsm-icon-location-1:before {
  content: '\e092';
}
.tsm-icon-map-pin-1:before {
  content: '\e093';
}
.tsm-icon-locator:before {
  content: '\e094';
}
.tsm-icon-map-1:before {
  content: '\e095';
}
.tsm-icon-address:before {
  content: '\e096';
}
.tsm-icon-start-time:before {
  content: '\e097';
}
.tsm-icon-edit-time:before {
  content: '\e098';
}
.tsm-icon-new-ticket:before {
  content: '\e099';
}
.tsm-icon-keyboard:before {
  content: '\e09a';
}
.tsm-icon-git-branch:before {
  content: '\e09b';
}
.tsm-icon-next:before {
  content: '\e09c';
}
.tsm-icon-next-1:before {
  content: '\e09d';
}
.tsm-icon-thermometer:before {
  content: '\e09e';
}
.tsm-icon-customer-support:before {
  content: '\e09f';
}
.tsm-icon-server:before {
  content: '\e0a0';
}
.tsm-icon-funnel:before {
  content: '\e0a1';
}
.tsm-icon-filter-2:before {
  content: '\e0a2';
}
.tsm-icon-filter:before {
  content: '\e0a3';
}
.tsm-icon-bad-review:before {
  content: '\e0a4';
}
.tsm-icon-thumb-down:before {
  content: '\e0a5';
}
.tsm-icon-process-1:before {
  content: '\e0a6';
}
.tsm-icon-promotion:before {
  content: '\e0a7';
}
.tsm-icon-card-payment:before {
  content: '\e0a8';
}
.tsm-icon-question:before {
  content: '\e0a9';
}
.tsm-icon-incoming-call:before {
  content: '\e0aa';
}
.tsm-icon-outgoing-call:before {
  content: '\e0ab';
}
.tsm-icon-megaphone:before {
  content: '\e0ac';
}
.tsm-icon-lock-3:before {
  content: '\e0ad';
}
.tsm-icon-lock-2:before {
  content: '\e0ae';
}
.tsm-icon-lock-1:before {
  content: '\e0af';
}
.tsm-icon-lock:before {
  content: '\e0b0';
}
.tsm-icon-lock-4:before {
  content: '\e0b1';
}
.tsm-icon-barcode-scanner:before {
  content: '\e0b2';
}
.tsm-icon-qr-code-scanner:before {
  content: '\e0b3';
}
.tsm-icon-scan:before {
  content: '\e0b4';
}
.tsm-icon-scan-1:before {
  content: '\e0b5';
}
.tsm-icon-scan-2:before {
  content: '\e0b6';
}
.tsm-icon-conversation:before {
  content: '\e0b7';
}
.tsm-icon-input:before {
  content: '\e0b8';
}
.tsm-icon-input-1:before {
  content: '\e0b9';
}
.tsm-icon-command-line:before {
  content: '\e0ba';
}
.tsm-icon-file:before {
  content: '\e0bb';
}
.tsm-icon-input-text:before {
  content: '\e0bc';
}
.tsm-icon-link-external-1:before {
  content: '\e0bd';
}
.tsm-icon-link-external-2:before {
  content: '\e0be';
}
.tsm-icon-link-external:before {
  content: '\e0bf';
}
.tsm-icon-svg:before {
  content: '\e0c0';
}
.tsm-icon-svg-file:before {
  content: '\e0c1';
}
.tsm-icon-pushpin-1:before {
  content: '\e0c2';
}
.tsm-icon-pushpin-2:before {
  content: '\e0c3';
}
.tsm-icon-locked:before {
  content: '\e0c4';
}
.tsm-icon-unlocked:before {
  content: '\e0c5';
}
.tsm-icon-overlapping:before {
  content: '\e0c6';
}
.tsm-icon-overlay:before {
  content: '\e0c7';
}
.tsm-icon-robot:before {
  content: '\e0c8';
}
.tsm-icon-robot-1:before {
  content: '\e0c9';
}
.tsm-icon-access-control:before {
  content: '\e0cb';
}
.tsm-icon-access-control-1:before {
  content: '\e0cc';
}
.tsm-icon-access:before {
  content: '\e0cf';
}
.tsm-icon-user-privileges-1:before {
  content: '\e0cd';
}
.tsm-icon-tag-outline:before {
  content: '\e0ce';
}
.tsm-icon-tag-solid:before {
  content: '\e0d0';
}
.tsm-icon-tag:before {
  content: '\e0d2';
}
.tsm-icon-tags:before {
  content: '\e0d3';
}
.tsm-icon-panel:before {
  content: '\e0d4';
}
.tsm-icon-tabpanel:before {
  content: '\e0d5';
}
.tsm-icon-splitter-horizontal:before {
  content: '\e0d6';
}
.tsm-icon-splitter-vertical:before {
  content: '\e0d7';
}
.tsm-icon-switch:before {
  content: '\e0d8';
}
.tsm-icon-button:before {
  content: '\e0d9';
}
.tsm-icon-button-1:before {
  content: '\e0da';
}
.tsm-icon-link:before {
  content: '\e0db';
}
.tsm-icon-password:before {
  content: '\e0dc';
}
.tsm-icon-console:before {
  content: '\e0dd';
}
.tsm-icon-layout-table:before {
  content: '\e0de';
}
.tsm-icon-layout-master-detail:before {
  content: '\e0df';
}
.tsm-icon-layout-table-detail:before {
  content: '\e0e0';
}
.tsm-icon-layout-tree:before {
  content: '\e0e1';
}
.tsm-icon-message-read:before {
  content: '\e0e2';
}
.tsm-icon-message-unread:before {
  content: '\e0e3';
}
.tsm-icon-messages:before {
  content: '\e0e4';
}
.tsm-icon-message-remove:before {
  content: '\e0e5';
}
.tsm-icon-message-add:before {
  content: '\e0e6';
}
.tsm-icon-message-download:before {
  content: '\e0e7';
}
.tsm-icon-message-upload:before {
  content: '\e0e8';
}
.tsm-icon-message-attachment:before {
  content: '\e0e9';
}
.tsm-icon-message-time:before {
  content: '\e0ea';
}
.tsm-icon-message-spam:before {
  content: '\e0eb';
}
.tsm-icon-message-warning:before {
  content: '\e0ec';
}
.tsm-icon-message-like:before {
  content: '\e0ed';
}
.tsm-icon-message-favourite:before {
  content: '\e0ee';
}
.tsm-icon-message-blocked:before {
  content: '\e0ef';
}
.tsm-icon-clipboard:before {
  content: '\e0f0';
}
.tsm-icon-clipboard-1:before {
  content: '\e0f2';
}
.tsm-icon-clipboard-copy:before {
  content: '\e0f3';
}
.tsm-icon-clipboard-paste:before {
  content: '\e0f4';
}
.tsm-icon-replace-1:before {
  content: '\e0f1';
}
.tsm-icon-replace-2:before {
  content: '\e0f5';
}
.tsm-icon-replace:before {
  content: '\e0f6';
}
.tsm-icon-replace-3:before {
  content: '\e0f7';
}
.tsm-icon-clipboard-done:before {
  content: '\e0f8';
}
.tsm-icon-alter-switch:before {
  content: '\e0f9';
}
.tsm-icon-change:before {
  content: '\e0fa';
}
.tsm-icon-to-console:before {
  content: '\e0fb';
}
.tsm-icon-paste:before {
  content: '\e0fc';
}
.tsm-icon-transfer-right:before {
  content: '\e0fd';
}
.tsm-icon-transfer-left:before {
  content: '\e0fe';
}
.tsm-icon-sending:before {
  content: '\e0ff';
}
.tsm-icon-file-transfer:before {
  content: '\e100';
}
.tsm-icon-direction-in:before {
  content: '\e101';
}
.tsm-icon-direction-out:before {
  content: '\e102';
}
.tsm-icon-direction-in-1:before {
  content: '\e103';
}
.tsm-icon-direction-out-1:before {
  content: '\e104';
}
.tsm-icon-budget:before {
  content: '\e105';
}
.tsm-icon-money:before {
  content: '\e106';
}
.tsm-icon-profits:before {
  content: '\e107';
}
.tsm-icon-list:before {
  content: '\e108';
}
.tsm-icon-list-outline:before {
  content: '\e109';
}
.tsm-icon-script-settings:before {
  content: '\e10a';
}
.tsm-icon-script-list-dialog:before {
  content: '\e10b';
}
.tsm-icon-event-1:before {
  content: '\e10c';
}
.tsm-icon-calendar-2:before {
  content: '\e10d';
}
.tsm-icon-leisure:before {
  content: '\e10e';
}
.tsm-icon-leisure-1:before {
  content: '\e10f';
}
.tsm-icon-no-signal:before {
  content: '\e110';
}
.tsm-icon-no-wifi:before {
  content: '\e111';
}
.tsm-icon-sick:before {
  content: '\e112';
}
.tsm-icon-sick-1:before {
  content: '\e113';
}
.tsm-icon-vacation:before {
  content: '\e114';
}
.tsm-icon-vacation-1:before {
  content: '\e115';
}
.tsm-icon-vacation-2:before {
  content: '\e116';
}
.tsm-icon-project-management:before {
  content: '\e117';
}
.tsm-icon-project-management-1:before {
  content: '\e118';
}
.tsm-icon-rocket:before {
  content: '\e119';
}
.tsm-icon-rocket-1:before {
  content: '\e11a';
}
.tsm-icon-project:before {
  content: '\e11b';
}
.tsm-icon-bar-chart:before {
  content: '\e11c';
}
.tsm-icon-bar-chart-1:before {
  content: '\e11d';
}
.tsm-icon-bar-chart-2:before {
  content: '\e11e';
}
.tsm-icon-graph:before {
  content: '\e11f';
}
.tsm-icon-line-chart:before {
  content: '\e120';
}
.tsm-icon-pie-chart:before {
  content: '\e121';
}
.tsm-icon-pie-chart-1:before {
  content: '\e122';
}
.tsm-icon-stats:before {
  content: '\e123';
}
.tsm-icon-placeholder:before {
  content: '\e124';
}
.tsm-icon-placeholder-1:before {
  content: '\e125';
}
.tsm-icon-product:before {
  content: '\e126';
}
.tsm-icon-component:before {
  content: '\e127';
}
.tsm-icon-set:before {
  content: '\e128';
}
.tsm-icon-product-1:before {
  content: '\e129';
}
.tsm-icon-set-1:before {
  content: '\e12a';
}
.tsm-icon-component-1:before {
  content: '\e12b';
}
.tsm-icon-keyboard-button:before {
  content: '\e12f';
}
.tsm-icon-keyboard-button-1:before {
  content: '\e130';
}
.tsm-icon-keyboard-arrows:before {
  content: '\e12c';
}
.tsm-icon-keyboard-arrows-1:before {
  content: '\e12d';
}
.tsm-icon-keyboard-1:before {
  content: '\e131';
}
.tsm-icon-keyboard-2:before {
  content: '\e132';
}
.tsm-icon-keyboard-button-2:before {
  content: '\e134';
}
.tsm-icon-keyboard-shortcuts:before {
  content: '\e12e';
}
.tsm-icon-keyboard-function:before {
  content: '\e136';
}
.tsm-icon-keyboard-alt:before {
  content: '\e137';
}
.tsm-icon-expense-1:before {
  content: '\e135';
}
.tsm-icon-economic:before {
  content: '\e138';
}
.tsm-icon-economic-1:before {
  content: '\e139';
}
.tsm-icon-economic-2:before {
  content: '\e13a';
}
.tsm-icon-economic-3:before {
  content: '\e13b';
}
.tsm-icon-evidence:before {
  content: '\e13c';
}
.tsm-icon-exchange-1:before {
  content: '\e13d';
}
.tsm-icon-expense:before {
  content: '\e13e';
}
.tsm-icon-economic-4:before {
  content: '\e13f';
}
.tsm-icon-folder-2:before {
  content: '\e140';
}
.tsm-icon-folder-3:before {
  content: '\e141';
}
.tsm-icon-growth-2:before {
  content: '\e142';
}
.tsm-icon-government:before {
  content: '\e143';
}
.tsm-icon-government-1:before {
  content: '\e144';
}
.tsm-icon-growing:before {
  content: '\e145';
}
.tsm-icon-growth-1:before {
  content: '\e146';
}
.tsm-icon-growth:before {
  content: '\e147';
}
.tsm-icon-increase:before {
  content: '\e148';
}
.tsm-icon-materials-2:before {
  content: '\e149';
}
.tsm-icon-money-bag-1:before {
  content: '\e14a';
}
.tsm-icon-money-bag-2:before {
  content: '\e14b';
}
.tsm-icon-materials:before {
  content: '\e14c';
}
.tsm-icon-materials-1:before {
  content: '\e14d';
}
.tsm-icon-recession:before {
  content: '\e14e';
}
.tsm-icon-reserve:before {
  content: '\e14f';
}
.tsm-icon-reserve-1:before {
  content: '\e150';
}
.tsm-icon-supply:before {
  content: '\e151';
}
.tsm-icon-warehouse-4:before {
  content: '\e152';
}
.tsm-icon-warehouse-5:before {
  content: '\e153';
}
.tsm-icon-warehouse-6:before {
  content: '\e154';
}
.tsm-icon-risk-management:before {
  content: '\e155';
}
.tsm-icon-tank:before {
  content: '\e133';
}
.tsm-icon-tank-1:before {
  content: '\e156';
}
.tsm-icon-oil-tank:before {
  content: '\e157';
}
.tsm-icon-business-man:before {
  content: '\61';
}
.tsm-icon-crm-1:before {
  content: '\e158';
}
.tsm-icon-crm-4:before {
  content: '\e159';
}
.tsm-icon-cycle:before {
  content: '\e15a';
}
.tsm-icon-cycle-1:before {
  content: '\e15b';
}
.tsm-icon-id-card:before {
  content: '\e15c';
}
.tsm-icon-management-5:before {
  content: '\e15d';
}
.tsm-icon-password-2:before {
  content: '\e15e';
}
.tsm-icon-password-1:before {
  content: '\e15f';
}
.tsm-icon-resource-1:before {
  content: '\e160';
}
.tsm-icon-caution:before {
  content: '\e161';
}
.tsm-icon-procedure:before {
  content: '\e162';
}
.tsm-icon-risk-management-1:before {
  content: '\e163';
}
.tsm-icon-risks:before {
  content: '\e164';
}
.tsm-icon-priority-1:before {
  content: '\e0ca';
}
.tsm-icon-priority-2:before {
  content: '\e165';
}
.tsm-icon-priority-3:before {
  content: '\e166';
}
.tsm-icon-draft-label:before {
  content: '\e167';
}
.tsm-icon-close:before {
  content: '\e168';
}
.tsm-icon-close-solid:before {
  content: '\e169';
}
.tsm-icon-bookmarked:before {
  content: '\e16a';
}
.tsm-icon-bookmark:before {
  content: '\e16b';
}
.tsm-icon-design-2:before {
  content: '\e16d';
}
.tsm-icon-draft-1:before {
  content: '\e16e';
}
.tsm-icon-drafts:before {
  content: '\e16f';
}
.tsm-icon-diskette:before {
  content: '\e170';
}
.tsm-icon-save-3:before {
  content: '\e171';
}
.tsm-icon-advice-solid:before {
  content: '\e172';
}
.tsm-icon-advice-outline:before {
  content: '\e173';
}
.tsm-icon-open-hand-solid:before {
  content: '\e174';
}
.tsm-icon-open-hand-outline:before {
  content: '\e175';
}
.tsm-icon-person-wait-solid:before {
  content: '\e176';
}
.tsm-icon-person-wait-outline:before {
  content: '\e177';
}
.tsm-icon-user-advice:before {
  content: '\e178';
}
.tsm-icon-waiting:before {
  content: '\e179';
}
.tsm-icon-waiting-1:before {
  content: '\e17a';
}
.tsm-icon-no-stopwatch:before {
  content: '\e17b';
}
.tsm-icon-stopwatch-1:before {
  content: '\e17c';
}
.tsm-icon-shipping-and-delivery:before {
  content: '\e17d';
}
.tsm-icon-no-timer:before {
  content: '\e17e';
}
.tsm-icon-user-advice-1:before {
  content: '\e17f';
}
.tsm-icon-wait-hand:before {
  content: '\e180';
}
.tsm-icon-drag:before {
  content: '\e16c';
}
.tsm-icon-privilege-escalation:before {
  content: '\e181';
}
.tsm-icon-turn-left:before {
  content: '\e184';
}
.tsm-icon-turn-right:before {
  content: '\e185';
}
.tsm-icon-undo:before {
  content: '\e186';
}
.tsm-icon-redo:before {
  content: '\e187';
}
.tsm-icon-undo-1:before {
  content: '\e188';
}
.tsm-icon-redo-1:before {
  content: '\e189';
}
.tsm-icon-repeat-2:before {
  content: '\e18c';
}
.tsm-icon-repeat-3:before {
  content: '\e18d';
}
.tsm-icon-forward:before {
  content: '\e182';
}
.tsm-icon-backward:before {
  content: '\e183';
}
.tsm-icon-redo-2:before {
  content: '\e18a';
}
.tsm-icon-undo-2:before {
  content: '\e18b';
}
.tsm-icon-pushpin-3:before {
  content: '\e18e';
}
.tsm-icon-pushpin-4:before {
  content: '\e18f';
}
.tsm-icon-pushpin-5:before {
  content: '\e190';
}
.tsm-icon-pushpin-6:before {
  content: '\e191';
}
.tsm-icon-pushpin-7:before {
  content: '\e192';
}
.tsm-icon-steps-menu:before {
  content: '\e193';
}
.tsm-icon-steps-menu-1:before {
  content: '\e194';
}
.tsm-icon-steps-menu-2:before {
  content: '\e195';
}
.tsm-icon-steps-menu-3:before {
  content: '\e196';
}
.tsm-icon-steps-menu-4:before {
  content: '\e197';
}
.tsm-icon-steps-menu-5:before {
  content: '\e198';
}
.tsm-icon-braces:before {
  content: '\e199';
}
.tsm-icon-bracket:before {
  content: '\e19a';
}
.tsm-icon-code:before {
  content: '\e19b';
}
.tsm-icon-code-1:before {
  content: '\e19c';
}
.tsm-icon-hierarchical-structure-2:before {
  content: '\e19d';
}
.tsm-icon-hashtag:before {
  content: '\e19e';
}
.tsm-icon-hierarchical-structure-1:before {
  content: '\e19f';
}
.tsm-icon-merge:before {
  content: '\e1a0';
}
.tsm-icon-tree-3:before {
  content: '\e1a1';
}
.tsm-icon-one-dot:before {
  content: '\e1a2';
}
.tsm-icon-download-arrow:before {
  content: '\e1a3';
}
.tsm-icon-bulk-edit-1:before {
  content: '\e1a4';
}
.tsm-icon-backup:before {
  content: '\e1a5';
}
.tsm-icon-backup-file:before {
  content: '\e1a6';
}
.tsm-icon-backup-cloud:before {
  content: '\e1a7';
}
.tsm-icon-cloud-storage:before {
  content: '\e1a8';
}
.tsm-icon-data-recovery-cloud:before {
  content: '\e1a9';
}
.tsm-icon-data-recovery:before {
  content: '\e1aa';
}
.tsm-icon-data-recovery-folder:before {
  content: '\e1ab';
}
.tsm-icon-user-privileges:before {
  content: '\e0d1';
}
.tsm-icon-notify-user:before {
  content: '\e1ac';
}
.tsm-icon-notify-user-group:before {
  content: '\e1ad';
}
.tsm-icon-notify-user-group-lock:before {
  content: '\e1ae';
}
.tsm-icon-filter-3:before {
  content: '\e1af';
}
.tsm-icon-filter-4:before {
  content: '\e1b0';
}
.tsm-icon-filter-5:before {
  content: '\e1b3';
}
.tsm-icon-filter-6:before {
  content: '\e1b1';
}
.tsm-icon-bug:before {
  content: '\e1b4';
}
.tsm-icon-user-privileges:before {
  content: '\e0d1';
}
.tsm-icon-notify-user:before {
  content: '\e1ac';
}
.tsm-icon-notify-user-group:before {
  content: '\e1ad';
}
.tsm-icon-notify-user-group-lock:before {
  content: '\e1ae';
}
.tsm-icon-filter-3:before {
  content: '\e1af';
}
.tsm-icon-filter-4:before {
  content: '\e1b0';
}
.tsm-icon-filter-5:before {
  content: '\e1b3';
}
.tsm-icon-filter-6:before {
  content: '\e1b1';
}
.tsm-icon-bug:before {
  content: '\e1b4';
}
