@media print {
  // Printed page setup
  @page {
    //size: A4;
    margin: 0.5cm;
  }

  // Hidden
  .no-print,
  tsm-topbar,
  .layout-menu,
  .layout-sidebar,
  .layout-menu-container,
  .p-button,
  dtl-detail-page-toolbar {
    display: none !important;
  }

  // Reset
  html {
    height: initial !important;
  }

  body {
    background-color: #fff !important;
    font-size: 12pt;
  }

  h1 {
    break-before: always;
  }

  .layout-container .layout-main {
    padding-top: 0 !important;
  }

  .layout-container.menu-layout-static .layout-main {
    margin-left: 0 !important;
  }

  dtl-detail-page-header {
    position: initial !important;
  }

  dtl-container-wrapper .p-container {
    margin: 15px 0 !important;
  }

  .p-shadow,
  .p-container {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  // printable docs
  .printable-area {
    .header {
      > span {
        font-size: 1.3em !important;
      }
    }
  }

  // flexbox
  .print-flex-column {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 0.125rem !important;
  }

  .md\:col-1,
  .md\:col-2,
  .md\:col-3,
  .md\:col-4,
  .md\:col-5,
  .md\:col-6,
  .md\:col-7,
  .md\:col-8,
  .md\:col-9,
  .md\:col-10,
  .md\:col-11,
  .md\:col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .md\:col-1 {
    width: 8.3333%;
  }

  .md\:col-2 {
    width: 16.6667%;
  }

  .md\:col-3 {
    width: 25%;
  }

  .md\:col-4 {
    width: 33.3333%;
  }

  .md\:col-5 {
    width: 41.6667%;
  }

  .md\:col-6 {
    width: 50%;
  }

  .md\:col-7 {
    width: 58.3333%;
  }

  .md\:col-8 {
    width: 66.6667%;
  }

  .md\:col-9 {
    width: 75%;
  }

  .md\:col-10 {
    width: 83.3333%;
  }

  .md\:col-11 {
    width: 91.6667%;
  }

  .md\:col-12 {
    width: 100%;
  }

  .md\:col-offset-12 {
    margin-left: 100%;
  }

  .md\:col-offset-11 {
    margin-left: 91.66666667%;
  }

  .md\:col-offset-10 {
    margin-left: 83.33333333%;
  }

  .md\:col-offset-9 {
    margin-left: 75%;
  }

  .md\:col-offset-8 {
    margin-left: 66.66666667%;
  }

  .md\:col-offset-7 {
    margin-left: 58.33333333%;
  }

  .md\:col-offset-6 {
    margin-left: 50%;
  }

  .md\:col-offset-5 {
    margin-left: 41.66666667%;
  }

  .md\:col-offset-4 {
    margin-left: 33.33333333%;
  }

  .md\:col-offset-3 {
    margin-left: 25%;
  }

  .md\:col-offset-2 {
    margin-left: 16.66666667%;
  }

  .md\:col-offset-1 {
    margin-left: 8.33333333%;
  }

  .md\:col-offset-0 {
    margin-left: 0%;
  }

  .md\:col-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .md\:col-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .md\:col-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .md\:col-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .md\:col-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .md\:col-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .md\:col-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .md\:col-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .md\:col-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .md\:col-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .md\:col-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .md\:col-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .md\:col-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .md\:col-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .md\:col-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
