.ui-icon-add-image:before {
  content: '\61';
}
.ui-icon-address-book:before {
  content: '\62';
}
.ui-icon-address-book-1:before {
  content: '\63';
}
.ui-icon-audit:before {
  content: '\64';
}
.ui-icon-bars-progress:before {
  content: '\65';
}
.ui-icon-bars-progress-1:before {
  content: '\66';
}
.ui-icon-book-bookmark:before {
  content: '\67';
}
.ui-icon-book-bookmark-2:before {
  content: '\69';
}
.ui-icon-booking:before {
  content: '\68';
}
.ui-icon-booking-1:before {
  content: '\6a';
}
.ui-icon-border-all:before {
  content: '\6b';
}
.ui-icon-cards-blank:before {
  content: '\6c';
}
.ui-icon-category-alt:before {
  content: '\6d';
}
.ui-icon-chart-pie-alt:before {
  content: '\6e';
}
.ui-icon-chart-pie-alt-1:before {
  content: '\6f';
}
.ui-icon-description-alt:before {
  content: '\70';
}
.ui-icon-description-alt-1:before {
  content: '\71';
}
.ui-icon-description-alt-2:before {
  content: '\72';
}
.ui-icon-dropdown:before {
  content: '\73';
}
.ui-icon-dropdown-1:before {
  content: '\74';
}
.ui-icon-envelope:before {
  content: '\75';
}
.ui-icon-envelope-1:before {
  content: '\76';
}
.ui-icon-envelope-open:before {
  content: '\77';
}
.ui-icon-envelope-open-1:before {
  content: '\78';
}
.ui-icon-grid-alt:before {
  content: '\79';
}
.ui-icon-input-numeric:before {
  content: '\7a';
}
.ui-icon-input-numeric-1:before {
  content: '\41';
}
.ui-icon-search:before {
  content: '\42';
}
.ui-icon-stats:before {
  content: '\43';
}
.ui-icon-table-list:before {
  content: '\45';
}
.ui-icon-table-list-1:before {
  content: '\46';
}
.ui-icon-table-tree:before {
  content: '\47';
}
.ui-icon-table-tree-1:before {
  content: '\48';
}
.ui-icon-table-tree-2:before {
  content: '\49';
}
.ui-icon-window-maximize:before {
  content: '\4a';
}
.ui-icon-window-maximize-1:before {
  content: '\4b';
}
.ui-icon-window-restore:before {
  content: '\4c';
}
.ui-icon-window-restore-1:before {
  content: '\4d';
}
.ui-icon-features:before {
  content: '\4e';
}
.ui-icon-features-1:before {
  content: '\4f';
}
.ui-icon-subscription-alt:before {
  content: '\44';
}
.ui-icon-invoice:before {
  content: '\50';
}
.ui-icon-catalog:before {
  content: '\51';
}
.ui-icon-catalog-1:before {
  content: '\52';
}
.ui-icon-product:before {
  content: '\53';
}
.ui-icon-service:before {
  content: '\54';
}
.ui-icon-service-1:before {
  content: '\55';
}
.ui-icon-voice-service:before {
  content: '\56';
}
.ui-icon-sms-service:before {
  content: '\57';
}
.ui-icon-data-service:before {
  content: '\58';
}
.ui-icon-internet-service:before {
  content: '\59';
}
.ui-icon-network:before {
  content: '\5a';
}
.ui-icon-router:before {
  content: '\30';
}
.ui-icon-satellite:before {
  content: '\31';
}
.ui-icon-sd-card:before {
  content: '\32';
}
.ui-icon-sim-card:before {
  content: '\33';
}
.ui-icon-settings:before {
  content: '\34';
}
.ui-icon-notes:before {
  content: '\35';
}
.ui-icon-status:before {
  content: '\36';
}
.ui-icon-dashboard:before {
  content: '\37';
}
.ui-icon-stats-1:before {
  content: '\38';
}
.ui-icon-attachment:before {
  content: '\39';
}
.ui-icon-detail:before {
  content: '\21';
}
.ui-icon-comment:before {
  content: '\22';
}
.ui-icon-event:before {
  content: '\23';
}
.ui-icon-history:before {
  content: '\24';
}
.ui-icon-filter:before {
  content: '\25';
}
.ui-icon-audit-1:before {
  content: '\26';
}
.ui-icon-archive:before {
  content: '\27';
}
.ui-icon-information:before {
  content: '\28';
}
.ui-icon-restricted:before {
  content: '\29';
}
.ui-icon-frog:before {
  content: '\2a';
}
.ui-icon-www:before {
  content: '\2b';
}
.ui-icon-error-404:before {
  content: '\2c';
}
.ui-icon-customer:before {
  content: '\2d';
}
.ui-icon-contact:before {
  content: '\2e';
}
.ui-icon-account:before {
  content: '\2f';
}
.ui-icon-address:before {
  content: '\3a';
}
.ui-icon-place:before {
  content: '\3b';
}
.ui-icon-lead:before {
  content: '\3c';
}
.ui-icon-person:before {
  content: '\3d';
}
.ui-icon-segment:before {
  content: '\3e';
}
.ui-icon-360-view:before {
  content: '\3f';
}
.ui-icon-360-view-1:before {
  content: '\40';
}
.ui-icon-financial-info:before {
  content: '\5b';
}
.ui-icon-crm:before {
  content: '\5d';
}
.ui-icon-company-location:before {
  content: '\5e';
}
.ui-icon-notification:before {
  content: '\5f';
}
.ui-icon-logo:before {
  content: '\60';
}
.ui-icon-file-pdf:before {
  content: '\7b';
}
.ui-icon-file-word:before {
  content: '\7c';
}
.ui-icon-file-excel:before {
  content: '\7d';
}
.ui-icon-file-powerpoint:before {
  content: '\7e';
}
.ui-icon-order:before {
  content: '\5c';
}
.ui-icon-new-order:before {
  content: '\e000';
}
.ui-icon-discount:before {
  content: '\e001';
}
.ui-icon-payment:before {
  content: '\e002';
}
.ui-icon-delivery:before {
  content: '\e003';
}
.ui-icon-basket:before {
  content: '\e004';
}
.ui-icon-delivery-1:before {
  content: '\e005';
}
.ui-icon-custom-actions:before {
  content: '\e006';
}
.ui-icon-cli:before {
  content: '\e007';
}
.ui-icon-warning:before {
  content: '\e008';
}
.ui-icon-stock-item:before {
  content: '\e009';
}
.ui-icon-ticket:before {
  content: '\e00a';
}
.ui-icon-tickets:before {
  content: '\e00b';
}
.ui-icon-process:before {
  content: '\e00c';
}
.ui-icon-tasks:before {
  content: '\e00d';
}
.ui-icon-task:before {
  content: '\e00e';
}
.ui-icon-kpi:before {
  content: '\e00f';
}
.ui-icon-user:before {
  content: '\e010';
}
.ui-icon-new-user:before {
  content: '\e011';
}
.ui-icon-user-group:before {
  content: '\e012';
}
.ui-icon-user-settings:before {
  content: '\e013';
}
