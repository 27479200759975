.layout-topbar-dark {
  --topbar-bg: #2b2d30;
  --topbar-start-bg: #2b2d30;
  --topbar-box-shadow: 0 1px 10px rgb(0 0 0 / 12%), 0 4px 5px rgb(0 0 0 / 14%),
    0 2px 4px -1px rgb(0 0 0 / 20%);
  --topbar-menu-button-bg: #e91e63;
  --topbar-menu-button-hover-bg: #c41953;
  --topbar-menu-button-text-color: #ffffff;
  --topbar-item-text-color: #ffffff;
  --topbar-item-hover-bg: rgba(255, 255, 255, 0.12);
}
