.layout-sidebar {
  box-shadow:
    0 24px 64px -2px rgb(0 0 0 / 2%),
    0 6px 16px -2px rgb(0 0 0 / 6%),
    0 2px 6px -2px rgb(0 0 0 / 8%);
  height: calc(100% - 3.5rem);
  top: 3.5rem;
  z-index: 4;

  .layout-menu {
    a {
      font-weight: 400;
    }

    ul {
      a:has(.layout-menuitem-bookmark-toggler) {
        &:hover {
          .layout-menuitem-bookmark-toggler {
            opacity: 1;
          }
        }
      }

      a {
        .layout-menuitem-icon {
          color: var(--primary-color);
          font-size: 1.325rem;
          //font-weight: 600;
        }

        .layout-menuitem-bookmark-toggler {
          color: var(--menuitem-text-color);
          font-size: 0.8rem;
          margin-left: -1.3em;
          opacity: 0;
          position: absolute;
          transition: opacity 0.3s ease;
        }
      }

      ul {
        border-radius: 0;
      }
    }

    .layout-root-menuitem > .layout-menuitem-root-text {
      font-weight: 400;
      color: var(--surface-500);
      padding-top: 1.5rem;
    }
  }
}
