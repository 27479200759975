@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./MaterialIcons-Regular.eot'); /* For IE6-8 */
  src:
    local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('./MaterialIcons-Regular.woff2') format('woff2'),
    url('./MaterialIcons-Regular.woff') format('woff'),
    url('./MaterialIcons-Regular.ttf') format('truetype');
}

// Variables
$iconPrefix: 'p-icon';
$matIconFontSize: 1rem;

@import './_mixins';
@import './_mat-icons_map';
