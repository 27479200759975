.layout-content-wrapper {
  padding-top: 3.5rem;

  //.layout-content {
  //  padding: 0 1.5rem 2rem 1.5rem !important;
  //}
}

.master-detail-container {
  dtl-fluent-tab:first-of-type {
    .p-tabview .p-tabview-panels {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
