// Resizable Common Styles
mwlResizable {
  box-sizing: border-box;
}

.resize-handle-left,
.resize-handle-right {
  background-color: transparent;
  cursor: e-resize;
  height: 100%;
  opacity: 0;
  position: absolute;
  transition-property: background-color, opacity;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  width: 6px;
  z-index: 2;

  &:hover {
    background-color: var(--yellow-200);
    opacity: 1;
  }
}

.resize-handle-left {
  left: 0;
}

.resize-handle-right {
  right: 0;
}

// tSM panels in tSM editors
// LEFT PANEL
.tsm-editor-left-panel {
  background-color: var(--surface-ground);
  max-height: calc(100vh - 155px);
  min-width: 40px;
  padding: 0;
  position: relative;
  width: 300px;

  .headline {
    .close-icon {
      border-radius: 15px;
      color: var(--surface-700);
      cursor: pointer;
      font-size: 1.25rem;
      position: absolute;
      top: 7px;
      left: 8px;
      transition: transform 0.3s ease;

      &:before {
        display: inline-block;
      }

      &:hover {
        background-color: var(--surface-hover);
      }
    }
  }

  .p-accordion {
    tsm-accordionTab .p-accordion-tab {
      .p-accordion-header {
        p-header > i {
          margin-right: 5px;
        }
      }
    }
  }

  &.collapsed {
    background-color: var(--surface-100);
    width: 40px !important;

    .headline {
      background-color: transparent;
      border-bottom-color: transparent !important;

      .p-selectbutton {
        display: none;
      }
    }

    .close-icon {
      transform: rotate(180deg) !important;
    }

    .left-content,
    .source-code-wrapper {
      display: none !important;
    }

    .resize-handle-right {
      cursor: none !important;
      display: none;
      pointer-events: none !important;
    }
  }
}

// RIGHT PANEL
.tsm-editor-right-panel {
  background-color: var(--surface-ground);
  height: calc(100vh - 155px);
  min-width: 40px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 350px;
  z-index: 1;

  .headline {
    .component-id-wrapper {
      align-items: center;
      min-height: 35px;

      .component-icon {
        flex: 0;
        margin-right: 3px;
        vertical-align: middle;
      }

      .p-inputtext {
        flex: 1;
        opacity: 1;
        filter: Alpha(Opacity=100);
      }
    }

    .close-icon {
      border-radius: 15px;
      color: var(--surface-700);
      cursor: pointer;
      font-size: 1.25rem;
      position: absolute;
      top: 7px;
      right: 8px;
      transition: transform 0.3s ease;

      &:before {
        display: inline-block;
      }

      &:hover {
        background-color: var(--surface-hover);
      }
    }
  }

  .p-accordion {
    tsm-accordionTab .p-accordion-tab {
      .p-accordion-header {
        p-header > i {
          margin-right: 5px;
        }
      }
    }
  }

  &.collapsed {
    background-color: var(--surface-100);
    width: 40px !important;

    .headline {
      background-color: transparent !important;
      border-bottom-color: transparent !important;

      .p-button {
        visibility: hidden;
      }
    }

    .close-icon {
      transform: rotate(180deg) !important;
      transition: transform 0.3s ease;
    }

    .content,
    .component-id-wrapper,
    .visualization,
    .p-selectbutton {
      display: none !important;
    }

    .resize-handle-left {
      cursor: none !important;
      display: none;
      pointer-events: none !important;
    }
  }
}
