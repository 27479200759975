.tsm-editor-icons-underline:before {
  content: '\70';
}
.tsm-editor-icons-subscript:before {
  content: '\43';
}
.tsm-editor-icons-superscript:before {
  content: '\44';
}
.tsm-editor-icons-strikethrough:before {
  content: '\4c';
}
.tsm-editor-icons-quote:before {
  content: '\4d';
}
.tsm-editor-icons-italic:before {
  content: '\4f';
}
.tsm-editor-icons-align-center:before {
  content: '\50';
}
.tsm-editor-icons-align-justify:before {
  content: '\51';
}
.tsm-editor-icons-align-left:before {
  content: '\52';
}
.tsm-editor-icons-align-right:before {
  content: '\53';
}
.tsm-editor-icons-indent-less:before {
  content: '\56';
}
.tsm-editor-icons-indent-more:before {
  content: '\57';
}
.tsm-editor-icons-text-color:before {
  content: '\59';
}
.tsm-editor-icons-save:before {
  content: '\31';
}
.tsm-editor-icons-print:before {
  content: '\32';
}
.tsm-editor-icons-paperclip:before {
  content: '\33';
}
.tsm-editor-icons-magnifying-glass:before {
  content: '\34';
}
.tsm-editor-icons-grid:before {
  content: '\38';
}
.tsm-editor-icons-bold:before {
  content: '\39';
}
.tsm-editor-icons-background-color:before {
  content: '\21';
}
.tsm-editor-icons-font-size:before {
  content: '\68';
}
.tsm-editor-icons-question:before {
  content: '\69';
}
.tsm-editor-icons-undo:before {
  content: '\6f';
}
.tsm-editor-icons-redo:before {
  content: '\71';
}
.tsm-editor-icons-image:before {
  content: '\62';
}
.tsm-editor-icons-remove-format:before {
  content: '\64';
}
.tsm-editor-icons-help:before {
  content: '\7a';
}
.tsm-editor-icons-link:before {
  content: '\66';
}
.tsm-editor-icons-link-1:before {
  content: '\61';
}
.tsm-editor-icons-048-bold:before {
  content: '\63';
}
.tsm-editor-icons-041-italic:before {
  content: '\65';
}
.tsm-editor-icons-030-underline:before {
  content: '\67';
}
.tsm-editor-icons-007-strikethrough-1:before {
  content: '\6a';
}
.tsm-editor-icons-036-quote:before {
  content: '\6b';
}
.tsm-editor-icons-022-link:before {
  content: '\6c';
}
.tsm-editor-icons-021-image:before {
  content: '\6d';
}
.tsm-editor-icons-047-clear:before {
  content: '\6e';
}
.tsm-editor-icons-066-grid:before {
  content: '\72';
}
.tsm-editor-icons-list:before {
  content: '\74';
}
.tsm-editor-icons-list-numbered-1:before {
  content: '\75';
}
.tsm-editor-icons-list-ordered:before {
  content: '\78';
}
.tsm-editor-icons-list-unordered:before {
  content: '\79';
}
.tsm-editor-icons-source-code:before {
  content: '\73';
}
.tsm-editor-icons-binary-code:before {
  content: '\76';
}
.tsm-editor-icons-coding-language:before {
  content: '\77';
}
.tsm-editor-icons-script-list-dialog:before {
  content: '\41';
}
