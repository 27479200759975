@font-face {
  font-family: 'tsm-editor-icons';
  src: url('./tsm-editor-icons.eot');
  src:
    url('./tsm-editor-icons.eot?#iefix') format('embedded-opentype'),
    url('./tsm-editor-icons.woff') format('woff'),
    url('./tsm-editor-icons.ttf') format('truetype'),
    url('./tsm-editor-icons.svg#tsm-editor-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before,
[class^='tsm-editor-icons-'],
[class*=' tsm-editor-icons-'] {
  font-family: 'tsm-editor-icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

[data-icon]:before {
  content: attr(data-icon);
}

@import 'tsm-editor-icons_map';
@import 'mixins';
