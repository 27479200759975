@media screen and (min-width: $breakpoint) {
  .layout-container {
    &.layout-static {
      .layout-topbar {
        .menu-toggle-button {
          i {
            transform: rotate(180deg);
          }

          svg.menu-button-icon {
            &.menu-opened {
              display: block;
            }

            &.menu-closed {
              display: none;
            }
          }
        }
      }
    }

    &.layout-static-inactive {
      .layout-topbar {
        .menu-toggle-button {
          i {
            transform: rotate(0deg);
          }

          svg.menu-button-icon {
            &.menu-opened {
              display: none;
            }

            &.menu-closed {
              display: block;
            }
          }
        }
      }
    }

    &.layout-overlay-inactive {
      .layout-topbar {
        .menu-toggle-button {
          i {
            transform: rotate(180deg);
          }

          svg.menu-button-icon {
            &.menu-opened {
              display: block;
            }

            &.menu-closed {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: ($breakpoint - 1)) {
  .layout-container {
    &.layout-static-inactive {
      .layout-topbar {
        .menu-toggle-button {
          i {
            transform: rotate(0deg);
          }

          svg.menu-button-icon {
            &.menu-opened {
              display: none;
            }

            &.menu-closed {
              display: block;
            }
          }
        }
      }
    }

    &.layout-mobile-active {
      .layout-topbar {
        .menu-toggle-button {
          i {
            transform: rotate(180deg);
          }

          svg.menu-button-icon {
            &.menu-opened {
              display: block;
            }

            &.menu-closed {
              display: none;
            }
          }
        }
      }
    }
  }

  .layout-topbar {
    flex-direction: row;
    justify-content: space-between;

    .layout-topbar-start {
      height: 3.5rem;
      padding-right: 0;
      width: auto;
    }

    .layout-topbar-end {
      display: flex;
      padding-right: 1rem;

      .layout-topbar-actions-end .layout-topbar-items {
        gap: 0.9rem;
      }

      sshr-organization-change {
        display: block;
        width: 50px;
      }
    }
  }
}

@media screen and (min-width: 1729px) {
  .layout-content {
    width: 100% !important;
  }
}
