@mixin mat-icon($icon) {
  display: inline-block;
  font-family: 'Material Icons';
  font-size: $matIconFontSize; /* Preferred icon size */
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: $icon;
  }
}

@mixin mat-icon-override($icon) {
  @include mat-icon($icon);
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

//@mixin mat-icon-override($icon) {
//  &:before {
//    content: $icon;
//  }
//}
