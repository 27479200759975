.#{$iconPrefix}-carat-2-n-s {
  @include mat-icon-override('sort');
}
.#{$iconPrefix}-triangle-1-n {
  @include mat-icon-override('keyboard_arrow_up');
}
.#{$iconPrefix}-triangle-1-e {
  @include mat-icon-override('keyboard_arrow_right');
}
.#{$iconPrefix}-triangle-1-s {
  @include mat-icon-override('keyboard_arrow_down');
}
.#{$iconPrefix}-triangle-1-w {
  @include mat-icon-override('keyboard_arrow_left');
}
.#{$iconPrefix}-carat-1-n {
  @include mat-icon-override('keyboard_arrow_up');
}
.#{$iconPrefix}-carat-1-e {
  @include mat-icon-override('keyboard_arrow_right');
}
.#{$iconPrefix}-carat-1-s {
  @include mat-icon-override('keyboard_arrow_down');
}
.#{$iconPrefix}-carat-1-w {
  @include mat-icon-override('keyboard_arrow_left');
}
.#{$iconPrefix}-arrow-1-n {
  @include mat-icon-override('play_arrow');
  @include rotate(-90deg);
}
.#{$iconPrefix}-arrowstop-1-n {
  @include mat-icon-override('skip_next');
  @include rotate(-90deg);
}
.#{$iconPrefix}-arrow-1-s {
  @include mat-icon-override('play_arrow');
  @include rotate(90deg);
}
.#{$iconPrefix}-arrowstop-1-s {
  @include mat-icon-override('skip_previous');
  @include rotate(-90deg);
}
.#{$iconPrefix}-arrow-1-w {
  @include mat-icon-override('play_arrow');
  @include rotate(180deg);
}
.#{$iconPrefix}-arrowstop-1-w {
  @include mat-icon-override('fast_rewind');
}
.#{$iconPrefix}-arrow-1-e {
  @include mat-icon-override('play_arrow');
}
.#{$iconPrefix}-arrowstop-1-e {
  @include mat-icon-override('fast_forward');
}
.#{$iconPrefix}-arrowthick-1-s {
  @include mat-icon-override('play_arrow');
  @include rotate(90deg);
}
.#{$iconPrefix}-arrowthick-1-n {
  @include mat-icon-override('play_arrow');
  @include rotate(-90deg);
}
.#{$iconPrefix}-circle-triangle-e {
  @include mat-icon-override('play_circle_outline');
}
.#{$iconPrefix}-circle-triangle-w {
  @include mat-icon-override('play_circle_outline');
  @include rotate(180deg);
}
.#{$iconPrefix}-circle-triangle-s {
  @include mat-icon-override('play_circle_outline');
  @include rotate(90deg);
}
.#{$iconPrefix}-radio-off {
  @include mat-icon-override('radio_button_unchecked');
}
.#{$iconPrefix}-radio-on {
  @include mat-icon-override('radio_button_checked');
}
.#{$iconPrefix}-folder-collapsed {
  @include mat-icon-override('folder');
}
.#{$iconPrefix}-document {
  @include mat-icon-override('content_paste');
}
.#{$iconPrefix}-video {
  @include mat-icon-override('movie');
}
.#{$iconPrefix}-music {
  @include mat-icon-override('music_note');
}
.#{$iconPrefix}-plus {
  @include mat-icon-override('add');
}
.#{$iconPrefix}-minus {
  @include mat-icon-override('remove');
}
.#{$iconPrefix}-plusthick {
  @include mat-icon-override('add');
}
.#{$iconPrefix}-minusthick {
  @include mat-icon-override('remove');
}
.#{$iconPrefix}-pencil {
  @include mat-icon-override('edit');
}
.#{$iconPrefix}-closethick {
  @include mat-icon-override('close');
}
.#{$iconPrefix}-circle-close {
  @include mat-icon-override('cancel');
}
.#{$iconPrefix}-gear {
  @include mat-icon-override('settings');
}
.#{$iconPrefix}-calendar {
  @include mat-icon-override('date_range');
}
.#{$iconPrefix}-trash {
  @include mat-icon-override('delete_forever');
}
.#{$iconPrefix}-notice {
  @include mat-icon-override('error_outline');
}
.#{$iconPrefix}-alert {
  @include mat-icon-override('warning');
}
.#{$iconPrefix}-circle-zoomin {
  @include mat-icon-override('zoom_in');
}
.#{$iconPrefix}-circle-zoomout {
  @include mat-icon-override('zoom_out');
}
.#{$iconPrefix}-circle-arrow-e {
  @include mat-icon-override('play_circle_outline');
}
.#{$iconPrefix}-circle-arrow-w {
  @include mat-icon-override('play_circle_outline');
  @include rotate(180deg);
}

.#{$iconPrefix}-3d-rotation {
  @include mat-icon-override('3d_rotation');
}
.#{$iconPrefix}-ac-unit {
  @include mat-icon-override('ac_unit');
}
.#{$iconPrefix}-access-alarm {
  @include mat-icon-override('access_alarm');
}
.#{$iconPrefix}-access-alarms {
  @include mat-icon-override('access_alarms');
}
.#{$iconPrefix}-access-time {
  @include mat-icon-override('access_time');
}
.#{$iconPrefix}-accessibility {
  @include mat-icon-override('accessibility');
}
.#{$iconPrefix}-accessible {
  @include mat-icon-override('accessible');
}
.#{$iconPrefix}-account-balance {
  @include mat-icon-override('account_balance');
}
.#{$iconPrefix}-account-balance-wallet {
  @include mat-icon-override('account_balance_wallet');
}
.#{$iconPrefix}-account-box {
  @include mat-icon-override('account_box');
}
.#{$iconPrefix}-account-circle {
  @include mat-icon-override('account_circle');
}
.#{$iconPrefix}-add {
  @include mat-icon-override('add');
}
.#{$iconPrefix}-add-a-photo {
  @include mat-icon-override('add_a_photo');
}
.#{$iconPrefix}-add-alarm {
  @include mat-icon-override('add_alarm');
}
.#{$iconPrefix}-add-alert {
  @include mat-icon-override('add_alert');
}
.#{$iconPrefix}-add-box {
  @include mat-icon-override('add_box');
}
.#{$iconPrefix}-add-circle {
  @include mat-icon-override('add_circle');
}
.#{$iconPrefix}-add-circle-outline {
  @include mat-icon-override('add_circle_outline');
}
.#{$iconPrefix}-add-location {
  @include mat-icon-override('add_location');
}
.#{$iconPrefix}-add-shopping-cart {
  @include mat-icon-override('add_shopping_cart');
}
.#{$iconPrefix}-add-to-photos {
  @include mat-icon-override('add_to_photos');
}
.#{$iconPrefix}-add-to-queue {
  @include mat-icon-override('add_to_queue');
}
.#{$iconPrefix}-adjust {
  @include mat-icon-override('adjust');
}
.#{$iconPrefix}-airline-seat-flat {
  @include mat-icon-override('airline_seat_flat');
}
.#{$iconPrefix}-airline-seat-flat-angled {
  @include mat-icon-override('airline_seat_flat_angled');
}
.#{$iconPrefix}-airline-seat-individual-suite {
  @include mat-icon-override('airline_seat_individual_suite');
}
.#{$iconPrefix}-airline-seat-legroom-extra {
  @include mat-icon-override('airline_seat_legroom_extra');
}
.#{$iconPrefix}-airline-seat-legroom-normal {
  @include mat-icon-override('airline_seat_legroom_normal');
}
.#{$iconPrefix}-airline-seat-legroom-reduced {
  @include mat-icon-override('airline_seat_legroom_reduced');
}
.#{$iconPrefix}-airline-seat-recline-extra {
  @include mat-icon-override('airline_seat_recline_extra');
}
.#{$iconPrefix}-airline-seat-recline-normal {
  @include mat-icon-override('airline_seat_recline_normal');
}
.#{$iconPrefix}-airplanemode-active {
  @include mat-icon-override('airplanemode_active');
}
.#{$iconPrefix}-airplanemode-inactive {
  @include mat-icon-override('airplanemode_inactive');
}
.#{$iconPrefix}-airplay {
  @include mat-icon-override('airplay');
}
.#{$iconPrefix}-airport-shuttle {
  @include mat-icon-override('airport_shuttle');
}
.#{$iconPrefix}-alarm {
  @include mat-icon-override('alarm');
}
.#{$iconPrefix}-alarm-add {
  @include mat-icon-override('alarm_add');
}
.#{$iconPrefix}-alarm-off {
  @include mat-icon-override('alarm_off');
}
.#{$iconPrefix}-alarm-on {
  @include mat-icon-override('alarm_on');
}
.#{$iconPrefix}-album {
  @include mat-icon-override('album');
}
.#{$iconPrefix}-all-inclusive {
  @include mat-icon-override('all_inclusive');
}
.#{$iconPrefix}-all-out {
  @include mat-icon-override('all_out');
}
.#{$iconPrefix}-android {
  @include mat-icon-override('android');
}
.#{$iconPrefix}-announcement {
  @include mat-icon-override('announcement');
}
.#{$iconPrefix}-apps {
  @include mat-icon-override('apps');
}
.#{$iconPrefix}-archive {
  @include mat-icon-override('archive');
}
.#{$iconPrefix}-arrow-back {
  @include mat-icon-override('arrow_back');
}
.#{$iconPrefix}-arrow-downward {
  @include mat-icon-override('arrow_downward');
}
.#{$iconPrefix}-arrow-drop-down {
  @include mat-icon-override('arrow_drop_down');
}
.#{$iconPrefix}-arrow-drop-down-circle {
  @include mat-icon-override('arrow_drop_down_circle');
}
.#{$iconPrefix}-arrow-drop-up {
  @include mat-icon-override('arrow_drop_up');
}
.#{$iconPrefix}-arrow-forward {
  @include mat-icon-override('arrow_forward');
}
.#{$iconPrefix}-arrow-upward {
  @include mat-icon-override('arrow_upward');
}
.#{$iconPrefix}-art-track {
  @include mat-icon-override('art_track');
}
.#{$iconPrefix}-aspect-ratio {
  @include mat-icon-override('aspect_ratio');
}
.#{$iconPrefix}-assessment {
  @include mat-icon-override('assessment');
}
.#{$iconPrefix}-assignment {
  @include mat-icon-override('assignment');
}
.#{$iconPrefix}-assignment-ind {
  @include mat-icon-override('assignment_ind');
}
.#{$iconPrefix}-assignment-late {
  @include mat-icon-override('assignment_late');
}
.#{$iconPrefix}-assignment-return {
  @include mat-icon-override('assignment_return');
}
.#{$iconPrefix}-assignment-returned {
  @include mat-icon-override('assignment_returned');
}
.#{$iconPrefix}-assignment-turned-in {
  @include mat-icon-override('assignment_turned_in');
}
.#{$iconPrefix}-assistant {
  @include mat-icon-override('assistant');
}
.#{$iconPrefix}-assistant-photo {
  @include mat-icon-override('assistant_photo');
}
.#{$iconPrefix}-attach-file {
  @include mat-icon-override('attach_file');
}
.#{$iconPrefix}-attach-money {
  @include mat-icon-override('attach_money');
}
.#{$iconPrefix}-attachment {
  @include mat-icon-override('attachment');
}
.#{$iconPrefix}-audiotrack {
  @include mat-icon-override('audiotrack');
}
.#{$iconPrefix}-autorenew {
  @include mat-icon-override('autorenew');
}
.#{$iconPrefix}-av-timer {
  @include mat-icon-override('av_timer');
}
.#{$iconPrefix}-backspace {
  @include mat-icon-override('backspace');
}
.#{$iconPrefix}-backup {
  @include mat-icon-override('backup');
}
.#{$iconPrefix}-battery-alert {
  @include mat-icon-override('battery_alert');
}
.#{$iconPrefix}-battery-charging-full {
  @include mat-icon-override('battery_charging_full');
}
.#{$iconPrefix}-battery-full {
  @include mat-icon-override('battery_full');
}
.#{$iconPrefix}-battery-std {
  @include mat-icon-override('battery_std');
}
.#{$iconPrefix}-battery-unknown {
  @include mat-icon-override('battery_unknown');
}
.#{$iconPrefix}-beach-access {
  @include mat-icon-override('beach_access');
}
.#{$iconPrefix}-beenhere {
  @include mat-icon-override('beenhere');
}
.#{$iconPrefix}-block {
  @include mat-icon-override('block');
}
.#{$iconPrefix}-bluetooth {
  @include mat-icon-override('bluetooth');
}
.#{$iconPrefix}-bluetooth-audio {
  @include mat-icon-override('bluetooth_audio');
}
.#{$iconPrefix}-bluetooth-connected {
  @include mat-icon-override('bluetooth_connected');
}
.#{$iconPrefix}-bluetooth-disabled {
  @include mat-icon-override('bluetooth_disabled');
}
.#{$iconPrefix}-bluetooth-searching {
  @include mat-icon-override('bluetooth_searching');
}
.#{$iconPrefix}-blur-circular {
  @include mat-icon-override('blur_circular');
}
.#{$iconPrefix}-blur-linear {
  @include mat-icon-override('blur_linear');
}
.#{$iconPrefix}-blur-off {
  @include mat-icon-override('blur_off');
}
.#{$iconPrefix}-blur-on {
  @include mat-icon-override('blur_on');
}
.#{$iconPrefix}-book {
  @include mat-icon-override('book');
}
.#{$iconPrefix}-bookmark {
  @include mat-icon-override('bookmark');
}
.#{$iconPrefix}-bookmark-border {
  @include mat-icon-override('bookmark_border');
}
.#{$iconPrefix}-border-all {
  @include mat-icon-override('border_all');
}
.#{$iconPrefix}-border-bottom {
  @include mat-icon-override('border_bottom');
}
.#{$iconPrefix}-border-clear {
  @include mat-icon-override('border_clear');
}
.#{$iconPrefix}-border-color {
  @include mat-icon-override('border_color');
}
.#{$iconPrefix}-border-horizontal {
  @include mat-icon-override('border_horizontal');
}
.#{$iconPrefix}-border-inner {
  @include mat-icon-override('border_inner');
}
.#{$iconPrefix}-border-left {
  @include mat-icon-override('border_left');
}
.#{$iconPrefix}-border-outer {
  @include mat-icon-override('border_outer');
}
.#{$iconPrefix}-border-right {
  @include mat-icon-override('border_right');
}
.#{$iconPrefix}-border-style {
  @include mat-icon-override('border_style');
}
.#{$iconPrefix}-border-top {
  @include mat-icon-override('border_top');
}
.#{$iconPrefix}-border-vertical {
  @include mat-icon-override('border_vertical');
}
.#{$iconPrefix}-branding-watermark {
  @include mat-icon-override('branding_watermark');
}
.#{$iconPrefix}-brightness-1 {
  @include mat-icon-override('brightness_1');
}
.#{$iconPrefix}-brightness-2 {
  @include mat-icon-override('brightness_2');
}
.#{$iconPrefix}-brightness-3 {
  @include mat-icon-override('brightness_3');
}
.#{$iconPrefix}-brightness-4 {
  @include mat-icon-override('brightness_4');
}
.#{$iconPrefix}-brightness-5 {
  @include mat-icon-override('brightness_5');
}
.#{$iconPrefix}-brightness-6 {
  @include mat-icon-override('brightness_6');
}
.#{$iconPrefix}-brightness-7 {
  @include mat-icon-override('brightness_7');
}
.#{$iconPrefix}-brightness-auto {
  @include mat-icon-override('brightness_auto');
}
.#{$iconPrefix}-brightness-high {
  @include mat-icon-override('brightness_high');
}
.#{$iconPrefix}-brightness-low {
  @include mat-icon-override('brightness_low');
}
.#{$iconPrefix}-brightness-medium {
  @include mat-icon-override('brightness_medium');
}
.#{$iconPrefix}-broken-image {
  @include mat-icon-override('broken_image');
}
.#{$iconPrefix}-brush {
  @include mat-icon-override('brush');
}
.#{$iconPrefix}-bubble-chart {
  @include mat-icon-override('bubble_chart');
}
.#{$iconPrefix}-bug-report {
  @include mat-icon-override('bug_report');
}
.#{$iconPrefix}-build {
  @include mat-icon-override('build');
}
.#{$iconPrefix}-burst-mode {
  @include mat-icon-override('burst_mode');
}
.#{$iconPrefix}-business {
  @include mat-icon-override('business');
}
.#{$iconPrefix}-business-center {
  @include mat-icon-override('business_center');
}
.#{$iconPrefix}-cached {
  @include mat-icon-override('cached');
}
.#{$iconPrefix}-cake {
  @include mat-icon-override('cake');
}
.#{$iconPrefix}-call {
  @include mat-icon-override('call');
}
.#{$iconPrefix}-call-end {
  @include mat-icon-override('call_end');
}
.#{$iconPrefix}-call-made {
  @include mat-icon-override('call_made');
}
.#{$iconPrefix}-call-merge {
  @include mat-icon-override('call_merge');
}
.#{$iconPrefix}-call-missed {
  @include mat-icon-override('call_missed');
}
.#{$iconPrefix}-call-missed-outgoing {
  @include mat-icon-override('call_missed_outgoing');
}
.#{$iconPrefix}-call-received {
  @include mat-icon-override('call_received');
}
.#{$iconPrefix}-call-split {
  @include mat-icon-override('call_split');
}
.#{$iconPrefix}-call-to-action {
  @include mat-icon-override('call_to_action');
}
.#{$iconPrefix}-camera {
  @include mat-icon-override('camera');
}
.#{$iconPrefix}-camera-alt {
  @include mat-icon-override('camera_alt');
}
.#{$iconPrefix}-camera-enhance {
  @include mat-icon-override('camera_enhance');
}
.#{$iconPrefix}-camera-front {
  @include mat-icon-override('camera_front');
}
.#{$iconPrefix}-camera-rear {
  @include mat-icon-override('camera_rear');
}
.#{$iconPrefix}-camera-roll {
  @include mat-icon-override('camera_roll');
}
.#{$iconPrefix}-cancel {
  @include mat-icon-override('cancel');
}
.#{$iconPrefix}-card-giftcard {
  @include mat-icon-override('card_giftcard');
}
.#{$iconPrefix}-card-membership {
  @include mat-icon-override('card_membership');
}
.#{$iconPrefix}-card-travel {
  @include mat-icon-override('card_travel');
}
.#{$iconPrefix}-casino {
  @include mat-icon-override('casino');
}
.#{$iconPrefix}-cast {
  @include mat-icon-override('cast');
}
.#{$iconPrefix}-cast-connected {
  @include mat-icon-override('cast_connected');
}
.#{$iconPrefix}-center-focus-strong {
  @include mat-icon-override('center_focus_strong');
}
.#{$iconPrefix}-center-focus-weak {
  @include mat-icon-override('center_focus_weak');
}
.#{$iconPrefix}-change-history {
  @include mat-icon-override('change_history');
}
.#{$iconPrefix}-chat {
  @include mat-icon-override('chat');
}
.#{$iconPrefix}-chat-bubble {
  @include mat-icon-override('chat_bubble');
}
.#{$iconPrefix}-chat-bubble-outline {
  @include mat-icon-override('chat_bubble_outline');
}
.#{$iconPrefix}-check {
  @include mat-icon-override('check');
}
.#{$iconPrefix}-check-box {
  @include mat-icon-override('check_box');
}
.#{$iconPrefix}-check-box-outline-blank {
  @include mat-icon-override('check_box_outline_blank');
}
.#{$iconPrefix}-check-circle {
  @include mat-icon-override('check_circle');
}
.#{$iconPrefix}-chevron-left {
  @include mat-icon-override('chevron_left');
}
.#{$iconPrefix}-chevron-right {
  @include mat-icon-override('chevron_right');
}
.#{$iconPrefix}-child-care {
  @include mat-icon-override('child_care');
}
.#{$iconPrefix}-child-friendly {
  @include mat-icon-override('child_friendly');
}
.#{$iconPrefix}-chrome-reader-mode {
  @include mat-icon-override('chrome_reader_mode');
}
.#{$iconPrefix}-class {
  @include mat-icon-override('class');
}
.#{$iconPrefix}-clear {
  @include mat-icon-override('clear');
}
.#{$iconPrefix}-clear-all {
  @include mat-icon-override('clear_all');
}
.#{$iconPrefix}-close {
  @include mat-icon-override('close');
}
.#{$iconPrefix}-closed-caption {
  @include mat-icon-override('closed_caption');
}
.#{$iconPrefix}-cloud {
  @include mat-icon-override('cloud');
}
.#{$iconPrefix}-cloud-circle {
  @include mat-icon-override('cloud_circle');
}
.#{$iconPrefix}-cloud-done {
  @include mat-icon-override('cloud_done');
}
.#{$iconPrefix}-cloud-download {
  @include mat-icon-override('cloud_download');
}
.#{$iconPrefix}-cloud-off {
  @include mat-icon-override('cloud_off');
}
.#{$iconPrefix}-cloud-queue {
  @include mat-icon-override('cloud_queue');
}
.#{$iconPrefix}-cloud-upload {
  @include mat-icon-override('cloud_upload');
}
.#{$iconPrefix}-code {
  @include mat-icon-override('code');
}
.#{$iconPrefix}-collections {
  @include mat-icon-override('collections');
}
.#{$iconPrefix}-collections-bookmark {
  @include mat-icon-override('collections_bookmark');
}
.#{$iconPrefix}-color-lens {
  @include mat-icon-override('color_lens');
}
.#{$iconPrefix}-colorize {
  @include mat-icon-override('colorize');
}
.#{$iconPrefix}-comment {
  @include mat-icon-override('comment');
}
.#{$iconPrefix}-compare {
  @include mat-icon-override('compare');
}
.#{$iconPrefix}-compare-arrows {
  @include mat-icon-override('compare_arrows');
}
.#{$iconPrefix}-computer {
  @include mat-icon-override('computer');
}
.#{$iconPrefix}-confirmation-number {
  @include mat-icon-override('confirmation_number');
}
.#{$iconPrefix}-contact-mail {
  @include mat-icon-override('contact_mail');
}
.#{$iconPrefix}-contact-phone {
  @include mat-icon-override('contact_phone');
}
.#{$iconPrefix}-contacts {
  @include mat-icon-override('contacts');
}
.#{$iconPrefix}-content-copy {
  @include mat-icon-override('content_copy');
}
.#{$iconPrefix}-content-cut {
  @include mat-icon-override('content_cut');
}
.#{$iconPrefix}-content-paste {
  @include mat-icon-override('content_paste');
}
.#{$iconPrefix}-control-point {
  @include mat-icon-override('control_point');
}
.#{$iconPrefix}-control-point-duplicate {
  @include mat-icon-override('control_point_duplicate');
}
.#{$iconPrefix}-copyright {
  @include mat-icon-override('copyright');
}
.#{$iconPrefix}-create {
  @include mat-icon-override('create');
}
.#{$iconPrefix}-create-new-folder {
  @include mat-icon-override('create_new_folder');
}
.#{$iconPrefix}-credit-card {
  @include mat-icon-override('credit_card');
}
.#{$iconPrefix}-crop {
  @include mat-icon-override('crop');
}
.#{$iconPrefix}-crop-16-9 {
  @include mat-icon-override('crop_16_9');
}
.#{$iconPrefix}-crop-3-2 {
  @include mat-icon-override('crop_3_2');
}
.#{$iconPrefix}-crop-5-4 {
  @include mat-icon-override('crop_5_4');
}
.#{$iconPrefix}-crop-7-5 {
  @include mat-icon-override('crop_7_5');
}
.#{$iconPrefix}-crop-din {
  @include mat-icon-override('crop_din');
}
.#{$iconPrefix}-crop-free {
  @include mat-icon-override('crop_free');
}
.#{$iconPrefix}-crop-landscape {
  @include mat-icon-override('crop_landscape');
}
.#{$iconPrefix}-crop-original {
  @include mat-icon-override('crop_original');
}
.#{$iconPrefix}-crop-portrait {
  @include mat-icon-override('crop_portrait');
}
.#{$iconPrefix}-crop-rotate {
  @include mat-icon-override('crop_rotate');
}
.#{$iconPrefix}-crop-square {
  @include mat-icon-override('crop_square');
}
.#{$iconPrefix}-dashboard {
  @include mat-icon-override('dashboard');
}
.#{$iconPrefix}-data-usage {
  @include mat-icon-override('data_usage');
}
.#{$iconPrefix}-date-range {
  @include mat-icon-override('date_range');
}
.#{$iconPrefix}-dehaze {
  @include mat-icon-override('dehaze');
}
.#{$iconPrefix}-delete {
  @include mat-icon-override('delete');
}
.#{$iconPrefix}-delete-forever {
  @include mat-icon-override('delete_forever');
}
.#{$iconPrefix}-delete-sweep {
  @include mat-icon-override('delete_sweep');
}
.#{$iconPrefix}-description {
  @include mat-icon-override('description');
}
.#{$iconPrefix}-desktop-mac {
  @include mat-icon-override('desktop_mac');
}
.#{$iconPrefix}-desktop-windows {
  @include mat-icon-override('desktop_windows');
}
.#{$iconPrefix}-details {
  @include mat-icon-override('details');
}
.#{$iconPrefix}-developer-board {
  @include mat-icon-override('developer_board');
}
.#{$iconPrefix}-developer-mode {
  @include mat-icon-override('developer_mode');
}
.#{$iconPrefix}-device-hub {
  @include mat-icon-override('device_hub');
}
.#{$iconPrefix}-devices {
  @include mat-icon-override('devices');
}
.#{$iconPrefix}-devices-other {
  @include mat-icon-override('devices_other');
}
.#{$iconPrefix}-dialer-sip {
  @include mat-icon-override('dialer_sip');
}
.#{$iconPrefix}-dialpad {
  @include mat-icon-override('dialpad');
}
.#{$iconPrefix}-directions {
  @include mat-icon-override('directions');
}
.#{$iconPrefix}-directions-bike {
  @include mat-icon-override('directions_bike');
}
.#{$iconPrefix}-directions-boat {
  @include mat-icon-override('directions_boat');
}
.#{$iconPrefix}-directions-bus {
  @include mat-icon-override('directions_bus');
}
.#{$iconPrefix}-directions-car {
  @include mat-icon-override('directions_car');
}
.#{$iconPrefix}-directions-railway {
  @include mat-icon-override('directions_railway');
}
.#{$iconPrefix}-directions-run {
  @include mat-icon-override('directions_run');
}
.#{$iconPrefix}-directions-subway {
  @include mat-icon-override('directions_subway');
}
.#{$iconPrefix}-directions-transit {
  @include mat-icon-override('directions_transit');
}
.#{$iconPrefix}-directions-walk {
  @include mat-icon-override('directions_walk');
}
.#{$iconPrefix}-disc-full {
  @include mat-icon-override('disc_full');
}
.#{$iconPrefix}-dns {
  @include mat-icon-override('dns');
}
.#{$iconPrefix}-do-not-disturb {
  @include mat-icon-override('do_not_disturb');
}
.#{$iconPrefix}-do-not-disturb-alt {
  @include mat-icon-override('do_not_disturb_alt');
}
.#{$iconPrefix}-do-not-disturb-off {
  @include mat-icon-override('do_not_disturb_off');
}
.#{$iconPrefix}-do-not-disturb-on {
  @include mat-icon-override('do_not_disturb_on');
}
.#{$iconPrefix}-dock {
  @include mat-icon-override('dock');
}
.#{$iconPrefix}-domain {
  @include mat-icon-override('domain');
}
.#{$iconPrefix}-done {
  @include mat-icon-override('done');
}
.#{$iconPrefix}-done-all {
  @include mat-icon-override('done_all');
}
.#{$iconPrefix}-donut-large {
  @include mat-icon-override('donut_large');
}
.#{$iconPrefix}-donut-small {
  @include mat-icon-override('donut_small');
}
.#{$iconPrefix}-drafts {
  @include mat-icon-override('drafts');
}
.#{$iconPrefix}-drag-handle {
  @include mat-icon-override('drag_handle');
}
.#{$iconPrefix}-drive-eta {
  @include mat-icon-override('drive_eta');
}
.#{$iconPrefix}-dvr {
  @include mat-icon-override('dvr');
}
.#{$iconPrefix}-edit {
  @include mat-icon-override('edit');
}
.#{$iconPrefix}-edit-location {
  @include mat-icon-override('edit_location');
}
.#{$iconPrefix}-eject {
  @include mat-icon-override('eject');
}
.#{$iconPrefix}-email {
  @include mat-icon-override('email');
}
.#{$iconPrefix}-enhanced-encryption {
  @include mat-icon-override('enhanced_encryption');
}
.#{$iconPrefix}-equalizer {
  @include mat-icon-override('equalizer');
}
.#{$iconPrefix}-error {
  @include mat-icon-override('error');
}
.#{$iconPrefix}-error-outline {
  @include mat-icon-override('error_outline');
}
.#{$iconPrefix}-euro-symbol {
  @include mat-icon-override('euro_symbol');
}
.#{$iconPrefix}-ev-station {
  @include mat-icon-override('ev_station');
}
.#{$iconPrefix}-event {
  @include mat-icon-override('event');
}
.#{$iconPrefix}-event-available {
  @include mat-icon-override('event_available');
}
.#{$iconPrefix}-event-busy {
  @include mat-icon-override('event_busy');
}
.#{$iconPrefix}-event-note {
  @include mat-icon-override('event_note');
}
.#{$iconPrefix}-event-seat {
  @include mat-icon-override('event_seat');
}
.#{$iconPrefix}-exit-to-app {
  @include mat-icon-override('exit_to_app');
}
.#{$iconPrefix}-expand-less {
  @include mat-icon-override('expand_less');
}
.#{$iconPrefix}-expand-more {
  @include mat-icon-override('expand_more');
}
.#{$iconPrefix}-explicit {
  @include mat-icon-override('explicit');
}
.#{$iconPrefix}-explore {
  @include mat-icon-override('explore');
}
.#{$iconPrefix}-exposure {
  @include mat-icon-override('exposure');
}
.#{$iconPrefix}-exposure-neg-1 {
  @include mat-icon-override('exposure_neg_1');
}
.#{$iconPrefix}-exposure-neg-2 {
  @include mat-icon-override('exposure_neg_2');
}
.#{$iconPrefix}-exposure-plus-1 {
  @include mat-icon-override('exposure_plus_1');
}
.#{$iconPrefix}-exposure-plus-2 {
  @include mat-icon-override('exposure_plus_2');
}
.#{$iconPrefix}-exposure-zero {
  @include mat-icon-override('exposure_zero');
}
.#{$iconPrefix}-extension {
  @include mat-icon-override('extension');
}
.#{$iconPrefix}-face {
  @include mat-icon-override('face');
}
.#{$iconPrefix}-fast-forward {
  @include mat-icon-override('fast_forward');
}
.#{$iconPrefix}-fast-rewind {
  @include mat-icon-override('fast_rewind');
}
.#{$iconPrefix}-favorite {
  @include mat-icon-override('favorite');
}
.#{$iconPrefix}-favorite-border {
  @include mat-icon-override('favorite_border');
}
.#{$iconPrefix}-featured-play-list {
  @include mat-icon-override('featured_play_list');
}
.#{$iconPrefix}-featured-video {
  @include mat-icon-override('featured_video');
}
.#{$iconPrefix}-feedback {
  @include mat-icon-override('feedback');
}
.#{$iconPrefix}-fiber-dvr {
  @include mat-icon-override('fiber_dvr');
}
.#{$iconPrefix}-fiber-manual-record {
  @include mat-icon-override('fiber_manual_record');
}
.#{$iconPrefix}-fiber-new {
  @include mat-icon-override('fiber_new');
}
.#{$iconPrefix}-fibern {
  @include mat-icon-override('fiber_pin');
}
.#{$iconPrefix}-fiber-smart-record {
  @include mat-icon-override('fiber_smart_record');
}
.#{$iconPrefix}-file-download {
  @include mat-icon-override('file_download');
}
.#{$iconPrefix}-file-upload {
  @include mat-icon-override('file_upload');
}
.#{$iconPrefix}-filter {
  @include mat-icon-override('filter');
}
.#{$iconPrefix}-filter-1 {
  @include mat-icon-override('filter_1');
}
.#{$iconPrefix}-filter-2 {
  @include mat-icon-override('filter_2');
}
.#{$iconPrefix}-filter-3 {
  @include mat-icon-override('filter_3');
}
.#{$iconPrefix}-filter-4 {
  @include mat-icon-override('filter_4');
}
.#{$iconPrefix}-filter-5 {
  @include mat-icon-override('filter_5');
}
.#{$iconPrefix}-filter-6 {
  @include mat-icon-override('filter_6');
}
.#{$iconPrefix}-filter-7 {
  @include mat-icon-override('filter_7');
}
.#{$iconPrefix}-filter-8 {
  @include mat-icon-override('filter_8');
}
.#{$iconPrefix}-filter-9 {
  @include mat-icon-override('filter_9');
}
.#{$iconPrefix}-filter-9-plus {
  @include mat-icon-override('filter_9_plus');
}
.#{$iconPrefix}-filter-b-and-w {
  @include mat-icon-override('filter_b_and_w');
}
.#{$iconPrefix}-filter-center-focus {
  @include mat-icon-override('filter_center_focus');
}
.#{$iconPrefix}-filter-drama {
  @include mat-icon-override('filter_drama');
}
.#{$iconPrefix}-filter-frames {
  @include mat-icon-override('filter_frames');
}
.#{$iconPrefix}-filter-hdr {
  @include mat-icon-override('filter_hdr');
}
.#{$iconPrefix}-filter-list {
  @include mat-icon-override('filter_list');
}
.#{$iconPrefix}-filter-none {
  @include mat-icon-override('filter_none');
}
.#{$iconPrefix}-filter-tilt-shift {
  @include mat-icon-override('filter_tilt_shift');
}
.#{$iconPrefix}-filter-vintage {
  @include mat-icon-override('filter_vintage');
}
.#{$iconPrefix}-find-in-page {
  @include mat-icon-override('find_in_page');
}
.#{$iconPrefix}-find-replace {
  @include mat-icon-override('find_replace');
}
.#{$iconPrefix}-fingerprint {
  @include mat-icon-override('fingerprint');
}
.#{$iconPrefix}-first-page {
  @include mat-icon-override('first_page');
}
.#{$iconPrefix}-fitness-center {
  @include mat-icon-override('fitness_center');
}
.#{$iconPrefix}-flag {
  @include mat-icon-override('flag');
}
.#{$iconPrefix}-flare {
  @include mat-icon-override('flare');
}
.#{$iconPrefix}-flash-auto {
  @include mat-icon-override('flash_auto');
}
.#{$iconPrefix}-flash-off {
  @include mat-icon-override('flash_off');
}
.#{$iconPrefix}-flash-on {
  @include mat-icon-override('flash_on');
}
.#{$iconPrefix}-flight {
  @include mat-icon-override('flight');
}
.#{$iconPrefix}-flight-land {
  @include mat-icon-override('flight_land');
}
.#{$iconPrefix}-flight-takeoff {
  @include mat-icon-override('flight_takeoff');
}
.#{$iconPrefix}-flip {
  @include mat-icon-override('flip');
}
.#{$iconPrefix}-flip-to-back {
  @include mat-icon-override('flip_to_back');
}
.#{$iconPrefix}-flip-to-front {
  @include mat-icon-override('flip_to_front');
}
.#{$iconPrefix}-folder {
  @include mat-icon-override('folder');
}
.#{$iconPrefix}-folder-open {
  @include mat-icon-override('folder_open');
}
.#{$iconPrefix}-folder-shared {
  @include mat-icon-override('folder_shared');
}
.#{$iconPrefix}-folder-special {
  @include mat-icon-override('folder_special');
}
.#{$iconPrefix}-font-download {
  @include mat-icon-override('font_download');
}
.#{$iconPrefix}-format-align-center {
  @include mat-icon-override('format_align_center');
}
.#{$iconPrefix}-format-align-justify {
  @include mat-icon-override('format_align_justify');
}
.#{$iconPrefix}-format-align-left {
  @include mat-icon-override('format_align_left');
}
.#{$iconPrefix}-format-align-right {
  @include mat-icon-override('format_align_right');
}
.#{$iconPrefix}-format-bold {
  @include mat-icon-override('format_bold');
}
.#{$iconPrefix}-format-clear {
  @include mat-icon-override('format_clear');
}
.#{$iconPrefix}-format-color-fill {
  @include mat-icon-override('format_color_fill');
}
.#{$iconPrefix}-format-color-reset {
  @include mat-icon-override('format_color_reset');
}
.#{$iconPrefix}-format-color-text {
  @include mat-icon-override('format_color_text');
}
.#{$iconPrefix}-format-indent-decrease {
  @include mat-icon-override('format_indent_decrease');
}
.#{$iconPrefix}-format-indent-increase {
  @include mat-icon-override('format_indent_increase');
}
.#{$iconPrefix}-format-italic {
  @include mat-icon-override('format_italic');
}
.#{$iconPrefix}-format-line-spacing {
  @include mat-icon-override('format_line_spacing');
}
.#{$iconPrefix}-format-list-bulleted {
  @include mat-icon-override('format_list_bulleted');
}
.#{$iconPrefix}-format-list-numbered {
  @include mat-icon-override('format_list_numbered');
}
.#{$iconPrefix}-format-paint {
  @include mat-icon-override('format_paint');
}
.#{$iconPrefix}-format-quote {
  @include mat-icon-override('format_quote');
}
.#{$iconPrefix}-format-shapes {
  @include mat-icon-override('format_shapes');
}
.#{$iconPrefix}-format-size {
  @include mat-icon-override('format_size');
}
.#{$iconPrefix}-format-strikethrough {
  @include mat-icon-override('format_strikethrough');
}
.#{$iconPrefix}-format-textdirection-l-to-r {
  @include mat-icon-override('format_textdirection_l_to_r');
}
.#{$iconPrefix}-format-textdirection-r-to-l {
  @include mat-icon-override('format_textdirection_r_to_l');
}
.#{$iconPrefix}-format-underlined {
  @include mat-icon-override('format_underlined');
}
.#{$iconPrefix}-forum {
  @include mat-icon-override('forum');
}
.#{$iconPrefix}-forward {
  @include mat-icon-override('forward');
}
.#{$iconPrefix}-forward-10 {
  @include mat-icon-override('forward_10');
}
.#{$iconPrefix}-forward-30 {
  @include mat-icon-override('forward_30');
}
.#{$iconPrefix}-forward-5 {
  @include mat-icon-override('forward_5');
}
.#{$iconPrefix}-free-breakfast {
  @include mat-icon-override('free_breakfast');
}
.#{$iconPrefix}-fullscreen {
  @include mat-icon-override('fullscreen');
}
.#{$iconPrefix}-fullscreen-exit {
  @include mat-icon-override('fullscreen_exit');
}
.#{$iconPrefix}-functions {
  @include mat-icon-override('functions');
}
.#{$iconPrefix}-g-translate {
  @include mat-icon-override('g_translate');
}
.#{$iconPrefix}-gamepad {
  @include mat-icon-override('gamepad');
}
.#{$iconPrefix}-games {
  @include mat-icon-override('games');
}
.#{$iconPrefix}-gavel {
  @include mat-icon-override('gavel');
}
.#{$iconPrefix}-gesture {
  @include mat-icon-override('gesture');
}
.#{$iconPrefix}-get-app {
  @include mat-icon-override('get_app');
}
.#{$iconPrefix}-gif {
  @include mat-icon-override('gif');
}
.#{$iconPrefix}-golf-course {
  @include mat-icon-override('golf_course');
}
.#{$iconPrefix}-gps-fixed {
  @include mat-icon-override('gps_fixed');
}
.#{$iconPrefix}-gps-not-fixed {
  @include mat-icon-override('gps_not_fixed');
}
.#{$iconPrefix}-gps-off {
  @include mat-icon-override('gps_off');
}
.#{$iconPrefix}-grade {
  @include mat-icon-override('grade');
}
.#{$iconPrefix}-gradient {
  @include mat-icon-override('gradient');
}
.#{$iconPrefix}-grain {
  @include mat-icon-override('grain');
}
.#{$iconPrefix}-graphic-eq {
  @include mat-icon-override('graphic_eq');
}
.#{$iconPrefix}-grid-off {
  @include mat-icon-override('grid_off');
}
.#{$iconPrefix}-grid-on {
  @include mat-icon-override('grid_on');
}
.#{$iconPrefix}-group {
  @include mat-icon-override('group');
}
.#{$iconPrefix}-group-add {
  @include mat-icon-override('group_add');
}
.#{$iconPrefix}-group-work {
  @include mat-icon-override('group_work');
}
.#{$iconPrefix}-hd {
  @include mat-icon-override('hd');
}
.#{$iconPrefix}-hdr-off {
  @include mat-icon-override('hdr_off');
}
.#{$iconPrefix}-hdr-on {
  @include mat-icon-override('hdr_on');
}
.#{$iconPrefix}-hdr-strong {
  @include mat-icon-override('hdr_strong');
}
.#{$iconPrefix}-hdr-weak {
  @include mat-icon-override('hdr_weak');
}
.#{$iconPrefix}-headset {
  @include mat-icon-override('headset');
}
.#{$iconPrefix}-headset-mic {
  @include mat-icon-override('headset_mic');
}
.#{$iconPrefix}-healing {
  @include mat-icon-override('healing');
}
.#{$iconPrefix}-hearing {
  @include mat-icon-override('hearing');
}
.#{$iconPrefix}-help {
  @include mat-icon-override('help');
}
.#{$iconPrefix}-help-outline {
  @include mat-icon-override('help_outline');
}
.#{$iconPrefix}-high-quality {
  @include mat-icon-override('high_quality');
}
.#{$iconPrefix}-highlight {
  @include mat-icon-override('highlight');
}
.#{$iconPrefix}-highlight-off {
  @include mat-icon-override('highlight_off');
}
.#{$iconPrefix}-history {
  @include mat-icon-override('history');
}
.#{$iconPrefix}-home {
  @include mat-icon-override('home');
}
.#{$iconPrefix}-hot-tub {
  @include mat-icon-override('hot_tub');
}
.#{$iconPrefix}-hotel {
  @include mat-icon-override('hotel');
}
.#{$iconPrefix}-hourglass-empty {
  @include mat-icon-override('hourglass_empty');
}
.#{$iconPrefix}-hourglass-full {
  @include mat-icon-override('hourglass_full');
}
.#{$iconPrefix}-http {
  @include mat-icon-override('http');
}
.#{$iconPrefix}-https {
  @include mat-icon-override('https');
}
.#{$iconPrefix}-image {
  @include mat-icon-override('image');
}
.#{$iconPrefix}-image-aspect-ratio {
  @include mat-icon-override('image_aspect_ratio');
}
.#{$iconPrefix}-import-contacts {
  @include mat-icon-override('import_contacts');
}
.#{$iconPrefix}-import-export {
  @include mat-icon-override('import_export');
}
.#{$iconPrefix}-important-devices {
  @include mat-icon-override('important_devices');
}
.#{$iconPrefix}-inbox {
  @include mat-icon-override('inbox');
}
.#{$iconPrefix}-indeterminate-check-box {
  @include mat-icon-override('indeterminate_check_box');
}
.#{$iconPrefix}-info {
  @include mat-icon-override('info');
}
.#{$iconPrefix}-info-outline {
  @include mat-icon-override('info_outline');
}
.#{$iconPrefix}-input {
  @include mat-icon-override('input');
}
.#{$iconPrefix}-insert-chart {
  @include mat-icon-override('insert_chart');
}
.#{$iconPrefix}-insert-comment {
  @include mat-icon-override('insert_comment');
}
.#{$iconPrefix}-insert-drive-file {
  @include mat-icon-override('insert_drive_file');
}
.#{$iconPrefix}-insert-emoticon {
  @include mat-icon-override('insert_emoticon');
}
.#{$iconPrefix}-insert-invitation {
  @include mat-icon-override('insert_invitation');
}
.#{$iconPrefix}-insert-link {
  @include mat-icon-override('insert_link');
}
.#{$iconPrefix}-insert-photo {
  @include mat-icon-override('insert_photo');
}
.#{$iconPrefix}-invert-colors {
  @include mat-icon-override('invert_colors');
}
.#{$iconPrefix}-invert-colors-off {
  @include mat-icon-override('invert_colors_off');
}
.#{$iconPrefix}-iso {
  @include mat-icon-override('iso');
}
.#{$iconPrefix}-keyboard {
  @include mat-icon-override('keyboard');
}
.#{$iconPrefix}-keyboard-arrow-down {
  @include mat-icon-override('keyboard_arrow_down');
}
.#{$iconPrefix}-keyboard-arrow-left {
  @include mat-icon-override('keyboard_arrow_left');
}
.#{$iconPrefix}-keyboard-arrow-right {
  @include mat-icon-override('keyboard_arrow_right');
}
.#{$iconPrefix}-keyboard-arrow-up {
  @include mat-icon-override('keyboard_arrow_up');
}
.#{$iconPrefix}-keyboard-backspace {
  @include mat-icon-override('keyboard_backspace');
}
.#{$iconPrefix}-keyboard-capslock {
  @include mat-icon-override('keyboard_capslock');
}
.#{$iconPrefix}-keyboard-hide {
  @include mat-icon-override('keyboard_hide');
}
.#{$iconPrefix}-keyboard-return {
  @include mat-icon-override('keyboard_return');
}
.#{$iconPrefix}-keyboard-tab {
  @include mat-icon-override('keyboard_tab');
}
.#{$iconPrefix}-keyboard-voice {
  @include mat-icon-override('keyboard_voice');
}
.#{$iconPrefix}-kitchen {
  @include mat-icon-override('kitchen');
}
.#{$iconPrefix}-label {
  @include mat-icon-override('label');
}
.#{$iconPrefix}-label-outline {
  @include mat-icon-override('label_outline');
}
.#{$iconPrefix}-landscape {
  @include mat-icon-override('landscape');
}
.#{$iconPrefix}-language {
  @include mat-icon-override('language');
}
.#{$iconPrefix}-laptop {
  @include mat-icon-override('laptop');
}
.#{$iconPrefix}-laptop-chromebook {
  @include mat-icon-override('laptop_chromebook');
}
.#{$iconPrefix}-laptop-mac {
  @include mat-icon-override('laptop_mac');
}
.#{$iconPrefix}-laptop-windows {
  @include mat-icon-override('laptop_windows');
}
.#{$iconPrefix}-last-page {
  @include mat-icon-override('last_page');
}
.#{$iconPrefix}-launch {
  @include mat-icon-override('launch');
}
.#{$iconPrefix}-layers {
  @include mat-icon-override('layers');
}
.#{$iconPrefix}-layers-clear {
  @include mat-icon-override('layers_clear');
}
.#{$iconPrefix}-leak-add {
  @include mat-icon-override('leak_add');
}
.#{$iconPrefix}-leak-remove {
  @include mat-icon-override('leak_remove');
}
.#{$iconPrefix}-lens {
  @include mat-icon-override('lens');
}
.#{$iconPrefix}-library-add {
  @include mat-icon-override('library_add');
}
.#{$iconPrefix}-library-books {
  @include mat-icon-override('library_books');
}
.#{$iconPrefix}-library-music {
  @include mat-icon-override('library_music');
}
.#{$iconPrefix}-lightbulb-outline {
  @include mat-icon-override('lightbulb_outline');
}
.#{$iconPrefix}-line-style {
  @include mat-icon-override('line_style');
}
.#{$iconPrefix}-line-weight {
  @include mat-icon-override('line_weight');
}
.#{$iconPrefix}-linear-scale {
  @include mat-icon-override('linear_scale');
}
.#{$iconPrefix}-link {
  @include mat-icon-override('link');
}
.#{$iconPrefix}-linked-camera {
  @include mat-icon-override('linked_camera');
}
.#{$iconPrefix}-list {
  @include mat-icon-override('list');
}
.#{$iconPrefix}-live-help {
  @include mat-icon-override('live_help');
}
.#{$iconPrefix}-live-tv {
  @include mat-icon-override('live_tv');
}
.#{$iconPrefix}-local-activity {
  @include mat-icon-override('local_activity');
}
.#{$iconPrefix}-local-airport {
  @include mat-icon-override('local_airport');
}
.#{$iconPrefix}-local-atm {
  @include mat-icon-override('local_atm');
}
.#{$iconPrefix}-local-bar {
  @include mat-icon-override('local_bar');
}
.#{$iconPrefix}-local-cafe {
  @include mat-icon-override('local_cafe');
}
.#{$iconPrefix}-local-car-wash {
  @include mat-icon-override('local_car_wash');
}
.#{$iconPrefix}-local-convenience-store {
  @include mat-icon-override('local_convenience_store');
}
.#{$iconPrefix}-local-dining {
  @include mat-icon-override('local_dining');
}
.#{$iconPrefix}-local-drink {
  @include mat-icon-override('local_drink');
}
.#{$iconPrefix}-local-florist {
  @include mat-icon-override('local_florist');
}
.#{$iconPrefix}-local-gas-station {
  @include mat-icon-override('local_gas_station');
}
.#{$iconPrefix}-local-grocery-store {
  @include mat-icon-override('local_grocery_store');
}
.#{$iconPrefix}-local-hospital {
  @include mat-icon-override('local_hospital');
}
.#{$iconPrefix}-local-hotel {
  @include mat-icon-override('local_hotel');
}
.#{$iconPrefix}-local-laundry-service {
  @include mat-icon-override('local_laundry_service');
}
.#{$iconPrefix}-local-library {
  @include mat-icon-override('local_library');
}
.#{$iconPrefix}-local-mall {
  @include mat-icon-override('local_mall');
}
.#{$iconPrefix}-local-movies {
  @include mat-icon-override('local_movies');
}
.#{$iconPrefix}-local-offer {
  @include mat-icon-override('local_offer');
}
.#{$iconPrefix}-local-parking {
  @include mat-icon-override('local_parking');
}
.#{$iconPrefix}-local-pharmacy {
  @include mat-icon-override('local_pharmacy');
}
.#{$iconPrefix}-local-phone {
  @include mat-icon-override('local_phone');
}
.#{$iconPrefix}-local-pizza {
  @include mat-icon-override('local_pizza');
}
.#{$iconPrefix}-local-play {
  @include mat-icon-override('local_play');
}
.#{$iconPrefix}-local-post-office {
  @include mat-icon-override('local_post_office');
}
.#{$iconPrefix}-local-printshop {
  @include mat-icon-override('local_printshop');
}
.#{$iconPrefix}-local-see {
  @include mat-icon-override('local_see');
}
.#{$iconPrefix}-local-shipping {
  @include mat-icon-override('local_shipping');
}
.#{$iconPrefix}-local-taxi {
  @include mat-icon-override('local_taxi');
}
.#{$iconPrefix}-location-city {
  @include mat-icon-override('location_city');
}
.#{$iconPrefix}-location-disabled {
  @include mat-icon-override('location_disabled');
}
.#{$iconPrefix}-location-off {
  @include mat-icon-override('location_off');
}
.#{$iconPrefix}-location-on {
  @include mat-icon-override('location_on');
}
.#{$iconPrefix}-location-searching {
  @include mat-icon-override('location_searching');
}
.#{$iconPrefix}-lock {
  @include mat-icon-override('lock');
}
.#{$iconPrefix}-lock-open {
  @include mat-icon-override('lock_open');
}
.#{$iconPrefix}-lock-outline {
  @include mat-icon-override('lock_outline');
}
.#{$iconPrefix}-looks {
  @include mat-icon-override('looks');
}
.#{$iconPrefix}-looks-3 {
  @include mat-icon-override('looks_3');
}
.#{$iconPrefix}-looks-4 {
  @include mat-icon-override('looks_4');
}
.#{$iconPrefix}-looks-5 {
  @include mat-icon-override('looks_5');
}
.#{$iconPrefix}-looks-6 {
  @include mat-icon-override('looks_6');
}
.#{$iconPrefix}-looks-one {
  @include mat-icon-override('looks_one');
}
.#{$iconPrefix}-looks-two {
  @include mat-icon-override('looks_two');
}
.#{$iconPrefix}-loop {
  @include mat-icon-override('loop');
}
.#{$iconPrefix}-loupe {
  @include mat-icon-override('loupe');
}
.#{$iconPrefix}-low-priority {
  @include mat-icon-override('low_priority');
}
.#{$iconPrefix}-loyalty {
  @include mat-icon-override('loyalty');
}
.#{$iconPrefix}-mail {
  @include mat-icon-override('mail');
}
.#{$iconPrefix}-mail-outline {
  @include mat-icon-override('mail_outline');
}
.#{$iconPrefix}-map {
  @include mat-icon-override('map');
}
.#{$iconPrefix}-markunread {
  @include mat-icon-override('markunread');
}
.#{$iconPrefix}-markunread-mailbox {
  @include mat-icon-override('markunread_mailbox');
}
.#{$iconPrefix}-memory {
  @include mat-icon-override('memory');
}
.#{$iconPrefix}-menu {
  @include mat-icon-override('menu');
}
.#{$iconPrefix}-merge-type {
  @include mat-icon-override('merge_type');
}
.#{$iconPrefix}-message {
  @include mat-icon-override('message');
}
.#{$iconPrefix}-mic {
  @include mat-icon-override('mic');
}
.#{$iconPrefix}-mic-none {
  @include mat-icon-override('mic_none');
}
.#{$iconPrefix}-mic-off {
  @include mat-icon-override('mic_off');
}
.#{$iconPrefix}-mms {
  @include mat-icon-override('mms');
}
.#{$iconPrefix}-mode-comment {
  @include mat-icon-override('mode_comment');
}
.#{$iconPrefix}-mode-edit {
  @include mat-icon-override('mode_edit');
}
.#{$iconPrefix}-monetization-on {
  @include mat-icon-override('monetization_on');
}
.#{$iconPrefix}-money-off {
  @include mat-icon-override('money_off');
}
.#{$iconPrefix}-monochrome-photos {
  @include mat-icon-override('monochrome_photos');
}
.#{$iconPrefix}-mood {
  @include mat-icon-override('mood');
}
.#{$iconPrefix}-mood-bad {
  @include mat-icon-override('mood_bad');
}
.#{$iconPrefix}-more {
  @include mat-icon-override('more');
}
.#{$iconPrefix}-more-horiz {
  @include mat-icon-override('more_horiz');
}
.#{$iconPrefix}-more-vert {
  @include mat-icon-override('more_vert');
}
.#{$iconPrefix}-motorcycle {
  @include mat-icon-override('motorcycle');
}
.#{$iconPrefix}-mouse {
  @include mat-icon-override('mouse');
}
.#{$iconPrefix}-move-to-inbox {
  @include mat-icon-override('move_to_inbox');
}
.#{$iconPrefix}-movie {
  @include mat-icon-override('movie');
}
.#{$iconPrefix}-movie-creation {
  @include mat-icon-override('movie_creation');
}
.#{$iconPrefix}-movie-filter {
  @include mat-icon-override('movie_filter');
}
.#{$iconPrefix}-multiline-chart {
  @include mat-icon-override('multiline_chart');
}
.#{$iconPrefix}-music-note {
  @include mat-icon-override('music_note');
}
.#{$iconPrefix}-music-video {
  @include mat-icon-override('music_video');
}
.#{$iconPrefix}-my-location {
  @include mat-icon-override('my_location');
}
.#{$iconPrefix}-nature {
  @include mat-icon-override('nature');
}
.#{$iconPrefix}-nature-people {
  @include mat-icon-override('nature_people');
}
.#{$iconPrefix}-navigate-before {
  @include mat-icon-override('navigate_before');
}
.#{$iconPrefix}-navigate-next {
  @include mat-icon-override('navigate_next');
}
.#{$iconPrefix}-navigation {
  @include mat-icon-override('navigation');
}
.#{$iconPrefix}-near-me {
  @include mat-icon-override('near_me');
}
.#{$iconPrefix}-network-cell {
  @include mat-icon-override('network_cell');
}
.#{$iconPrefix}-network-check {
  @include mat-icon-override('network_check');
}
.#{$iconPrefix}-network-locked {
  @include mat-icon-override('network_locked');
}
.#{$iconPrefix}-network-wifi {
  @include mat-icon-override('network_wifi');
}
.#{$iconPrefix}-new-releases {
  @include mat-icon-override('new_releases');
}
.#{$iconPrefix}-next-week {
  @include mat-icon-override('next_week');
}
.#{$iconPrefix}-nfc {
  @include mat-icon-override('nfc');
}
.#{$iconPrefix}-no-encryption {
  @include mat-icon-override('no_encryption');
}
.#{$iconPrefix}-no-sim {
  @include mat-icon-override('no_sim');
}
.#{$iconPrefix}-not-interested {
  @include mat-icon-override('not_interested');
}
.#{$iconPrefix}-note {
  @include mat-icon-override('note');
}
.#{$iconPrefix}-note-add {
  @include mat-icon-override('note_add');
}
.#{$iconPrefix}-notifications {
  @include mat-icon-override('notifications');
}
.#{$iconPrefix}-notifications-active {
  @include mat-icon-override('notifications_active');
}
.#{$iconPrefix}-notifications-none {
  @include mat-icon-override('notifications_none');
}
.#{$iconPrefix}-notifications-off {
  @include mat-icon-override('notifications_off');
}
.#{$iconPrefix}-notifications-paused {
  @include mat-icon-override('notifications_paused');
}
.#{$iconPrefix}-offline-pin {
  @include mat-icon-override('offline_pin');
}
.#{$iconPrefix}-ondemand-video {
  @include mat-icon-override('ondemand_video');
}
.#{$iconPrefix}-opacity {
  @include mat-icon-override('opacity');
}
.#{$iconPrefix}-open-in-browser {
  @include mat-icon-override('open_in_browser');
}
.#{$iconPrefix}-open-in-new {
  @include mat-icon-override('open_in_new');
}
.#{$iconPrefix}-open-with {
  @include mat-icon-override('open_with');
}
.#{$iconPrefix}-pages {
  @include mat-icon-override('pages');
}
.#{$iconPrefix}-pageview {
  @include mat-icon-override('pageview');
}
.#{$iconPrefix}-palette {
  @include mat-icon-override('palette');
}
.#{$iconPrefix}-pan-tool {
  @include mat-icon-override('pan_tool');
}
.#{$iconPrefix}-panorama {
  @include mat-icon-override('panorama');
}
.#{$iconPrefix}-panorama-fish-eye {
  @include mat-icon-override('panorama_fish_eye');
}
.#{$iconPrefix}-panorama-horizontal {
  @include mat-icon-override('panorama_horizontal');
}
.#{$iconPrefix}-panorama-vertical {
  @include mat-icon-override('panorama_vertical');
}
.#{$iconPrefix}-panorama-wide-angle {
  @include mat-icon-override('panorama_wide_angle');
}
.#{$iconPrefix}-party-mode {
  @include mat-icon-override('party_mode');
}
.#{$iconPrefix}-pause {
  @include mat-icon-override('pause');
}
.#{$iconPrefix}-pause-circle-filled {
  @include mat-icon-override('pause_circle_filled');
}
.#{$iconPrefix}-pause-circle-outline {
  @include mat-icon-override('pause_circle_outline');
}
.#{$iconPrefix}-payment {
  @include mat-icon-override('payment');
}
.#{$iconPrefix}-people {
  @include mat-icon-override('people');
}
.#{$iconPrefix}-people-outline {
  @include mat-icon-override('people_outline');
}
.#{$iconPrefix}-perm-camera-mic {
  @include mat-icon-override('perm_camera_mic');
}
.#{$iconPrefix}-perm-contact-calendar {
  @include mat-icon-override('perm_contact_calendar');
}
.#{$iconPrefix}-perm-data-setting {
  @include mat-icon-override('perm_data_setting');
}
.#{$iconPrefix}-perm-device-information {
  @include mat-icon-override('perm_device_information');
}
.#{$iconPrefix}-perm-identity {
  @include mat-icon-override('perm_identity');
}
.#{$iconPrefix}-perm-media {
  @include mat-icon-override('perm_media');
}
.#{$iconPrefix}-perm-phone-msg {
  @include mat-icon-override('perm_phone_msg');
}
.#{$iconPrefix}-perm-scan-wifi {
  @include mat-icon-override('perm_scan_wifi');
}
.#{$iconPrefix}-person {
  @include mat-icon-override('person');
}
.#{$iconPrefix}-person-add {
  @include mat-icon-override('person_add');
}
.#{$iconPrefix}-person-outline {
  @include mat-icon-override('person_outline');
}
.#{$iconPrefix}-person-pin {
  @include mat-icon-override('person_pin');
}
.#{$iconPrefix}-person-pin-circle {
  @include mat-icon-override('person_pin_circle');
}
.#{$iconPrefix}-personal-video {
  @include mat-icon-override('personal_video');
}
.#{$iconPrefix}-pets {
  @include mat-icon-override('pets');
}
.#{$iconPrefix}-phone {
  @include mat-icon-override('phone');
}
.#{$iconPrefix}-phone-android {
  @include mat-icon-override('phone_android');
}
.#{$iconPrefix}-phone-bluetooth-speaker {
  @include mat-icon-override('phone_bluetooth_speaker');
}
.#{$iconPrefix}-phone-forwarded {
  @include mat-icon-override('phone_forwarded');
}
.#{$iconPrefix}-phone-in-talk {
  @include mat-icon-override('phone_in_talk');
}
.#{$iconPrefix}-phone-iphone {
  @include mat-icon-override('phone_iphone');
}
.#{$iconPrefix}-phone-locked {
  @include mat-icon-override('phone_locked');
}
.#{$iconPrefix}-phone-missed {
  @include mat-icon-override('phone_missed');
}
.#{$iconPrefix}-phone-paused {
  @include mat-icon-override('phone_paused');
}
.#{$iconPrefix}-phonelink {
  @include mat-icon-override('phonelink');
}
.#{$iconPrefix}-phonelink-erase {
  @include mat-icon-override('phonelink_erase');
}
.#{$iconPrefix}-phonelink-lock {
  @include mat-icon-override('phonelink_lock');
}
.#{$iconPrefix}-phonelink-off {
  @include mat-icon-override('phonelink_off');
}
.#{$iconPrefix}-phonelink-ring {
  @include mat-icon-override('phonelink_ring');
}
.#{$iconPrefix}-phonelink-setup {
  @include mat-icon-override('phonelink_setup');
}
.#{$iconPrefix}-photo {
  @include mat-icon-override('photo');
}
.#{$iconPrefix}-photo-album {
  @include mat-icon-override('photo_album');
}
.#{$iconPrefix}-photo-camera {
  @include mat-icon-override('photo_camera');
}
.#{$iconPrefix}-photo-filter {
  @include mat-icon-override('photo_filter');
}
.#{$iconPrefix}-photo-library {
  @include mat-icon-override('photo_library');
}
.#{$iconPrefix}-photo-size-select-actual {
  @include mat-icon-override('photo_size_select_actual');
}
.#{$iconPrefix}-photo-size-select-large {
  @include mat-icon-override('photo_size_select_large');
}
.#{$iconPrefix}-photo-size-select-small {
  @include mat-icon-override('photo_size_select_small');
}
.#{$iconPrefix}-picture-as-pdf {
  @include mat-icon-override('picture_as_pdf');
}
.#{$iconPrefix}-picture-in-picture {
  @include mat-icon-override('picture_in_picture');
}
.#{$iconPrefix}-picture-in-picture-alt {
  @include mat-icon-override('picture_in_picture_alt');
}
.#{$iconPrefix}-pie-chart {
  @include mat-icon-override('pie_chart');
}
.#{$iconPrefix}-pie-chart-outlined {
  @include mat-icon-override('pie_chart_outlined');
}
.#{$iconPrefix}-pin-drop {
  @include mat-icon-override('pin_drop');
}
.#{$iconPrefix}-place {
  @include mat-icon-override('place');
}
.#{$iconPrefix}-play-arrow {
  @include mat-icon-override('play_arrow');
}
.#{$iconPrefix}-play-circle-filled {
  @include mat-icon-override('play_circle_filled');
}
.#{$iconPrefix}-play-circle-outline {
  @include mat-icon-override('play_circle_outline');
}
.#{$iconPrefix}-play-for-work {
  @include mat-icon-override('play_for_work');
}
.#{$iconPrefix}-playlist-add {
  @include mat-icon-override('playlist_add');
}
.#{$iconPrefix}-playlist-add-check {
  @include mat-icon-override('playlist_add_check');
}
.#{$iconPrefix}-playlist-play {
  @include mat-icon-override('playlist_play');
}
.#{$iconPrefix}-plus-one {
  @include mat-icon-override('plus_one');
}
.#{$iconPrefix}-poll {
  @include mat-icon-override('poll');
}
.#{$iconPrefix}-polymer {
  @include mat-icon-override('polymer');
}
.#{$iconPrefix}-pool {
  @include mat-icon-override('pool');
}
.#{$iconPrefix}-portable-wifi-off {
  @include mat-icon-override('portable_wifi_off');
}
.#{$iconPrefix}-portrait {
  @include mat-icon-override('portrait');
}
.#{$iconPrefix}-power {
  @include mat-icon-override('power');
}
.#{$iconPrefix}-power-input {
  @include mat-icon-override('power_input');
}
.#{$iconPrefix}-power-settings-new {
  @include mat-icon-override('power_settings_new');
}
.#{$iconPrefix}-pregnant-woman {
  @include mat-icon-override('pregnant_woman');
}
.#{$iconPrefix}-present-to-all {
  @include mat-icon-override('present_to_all');
}
.#{$iconPrefix}-print {
  @include mat-icon-override('print');
}
.#{$iconPrefix}-priority-high {
  @include mat-icon-override('priority_high');
}
.#{$iconPrefix}-public {
  @include mat-icon-override('public');
}
.#{$iconPrefix}-publish {
  @include mat-icon-override('publish');
}
.#{$iconPrefix}-query-builder {
  @include mat-icon-override('query_builder');
}
.#{$iconPrefix}-question-answer {
  @include mat-icon-override('question_answer');
}
.#{$iconPrefix}-queue {
  @include mat-icon-override('queue');
}
.#{$iconPrefix}-queue-music {
  @include mat-icon-override('queue_music');
}
.#{$iconPrefix}-queue-play-next {
  @include mat-icon-override('queue_play_next');
}
.#{$iconPrefix}-radio {
  @include mat-icon-override('radio');
}
.#{$iconPrefix}-radio-button-checked {
  @include mat-icon-override('radio_button_checked');
}
.#{$iconPrefix}-radio-button-unchecked {
  @include mat-icon-override('radio_button_unchecked');
}
.#{$iconPrefix}-rate-review {
  @include mat-icon-override('rate_review');
}
.#{$iconPrefix}-receipt {
  @include mat-icon-override('receipt');
}
.#{$iconPrefix}-recent-actors {
  @include mat-icon-override('recent_actors');
}
.#{$iconPrefix}-record-voice-over {
  @include mat-icon-override('record_voice_over');
}
.#{$iconPrefix}-redeem {
  @include mat-icon-override('redeem');
}
.#{$iconPrefix}-redo {
  @include mat-icon-override('redo');
}
.#{$iconPrefix}-refresh {
  @include mat-icon-override('refresh');
}
.#{$iconPrefix}-remove {
  @include mat-icon-override('remove');
}
.#{$iconPrefix}-remove-circle {
  @include mat-icon-override('remove_circle');
}
.#{$iconPrefix}-remove-circle-outline {
  @include mat-icon-override('remove_circle_outline');
}
.#{$iconPrefix}-remove-from-queue {
  @include mat-icon-override('remove_from_queue');
}
.#{$iconPrefix}-remove-red-eye {
  @include mat-icon-override('remove_red_eye');
}
.#{$iconPrefix}-remove-shopping-cart {
  @include mat-icon-override('remove_shopping_cart');
}
.#{$iconPrefix}-reorder {
  @include mat-icon-override('reorder');
}
.#{$iconPrefix}-repeat {
  @include mat-icon-override('repeat');
}
.#{$iconPrefix}-repeat-one {
  @include mat-icon-override('repeat_one');
}
.#{$iconPrefix}-replay {
  @include mat-icon-override('replay');
}
.#{$iconPrefix}-replay-10 {
  @include mat-icon-override('replay_10');
}
.#{$iconPrefix}-replay-30 {
  @include mat-icon-override('replay_30');
}
.#{$iconPrefix}-replay-5 {
  @include mat-icon-override('replay_5');
}
.#{$iconPrefix}-reply {
  @include mat-icon-override('reply');
}
.#{$iconPrefix}-reply-all {
  @include mat-icon-override('reply_all');
}
.#{$iconPrefix}-report {
  @include mat-icon-override('report');
}
.#{$iconPrefix}-report-problem {
  @include mat-icon-override('report_problem');
}
.#{$iconPrefix}-restaurant {
  @include mat-icon-override('restaurant');
}
.#{$iconPrefix}-restaurant-menu {
  @include mat-icon-override('restaurant_menu');
}
.#{$iconPrefix}-restore {
  @include mat-icon-override('restore');
}
.#{$iconPrefix}-restore-page {
  @include mat-icon-override('restore_page');
}
.#{$iconPrefix}-ring-volume {
  @include mat-icon-override('ring_volume');
}
.#{$iconPrefix}-room {
  @include mat-icon-override('room');
}
.#{$iconPrefix}-room-service {
  @include mat-icon-override('room_service');
}
.#{$iconPrefix}-rotate-90-degrees-ccw {
  @include mat-icon-override('rotate_90_degrees_ccw');
}
.#{$iconPrefix}-rotate-left {
  @include mat-icon-override('rotate_left');
}
.#{$iconPrefix}-rotate-right {
  @include mat-icon-override('rotate_right');
}
.#{$iconPrefix}-rounded-corner {
  @include mat-icon-override('rounded_corner');
}
.#{$iconPrefix}-router {
  @include mat-icon-override('router');
}
.#{$iconPrefix}-rowing {
  @include mat-icon-override('rowing');
}
.#{$iconPrefix}-rss-feed {
  @include mat-icon-override('rss_feed');
}
.#{$iconPrefix}-rv-hookup {
  @include mat-icon-override('rv_hookup');
}
.#{$iconPrefix}-satellite {
  @include mat-icon-override('satellite');
}
.#{$iconPrefix}-save {
  @include mat-icon-override('save');
}
.#{$iconPrefix}-scanner {
  @include mat-icon-override('scanner');
}
.#{$iconPrefix}-schedule {
  @include mat-icon-override('schedule');
}
.#{$iconPrefix}-school {
  @include mat-icon-override('school');
}
.#{$iconPrefix}-screen-lock-landscape {
  @include mat-icon-override('screen_lock_landscape');
}
.#{$iconPrefix}-screen-lock-portrait {
  @include mat-icon-override('screen_lock_portrait');
}
.#{$iconPrefix}-screen-lock-rotation {
  @include mat-icon-override('screen_lock_rotation');
}
.#{$iconPrefix}-screen-rotation {
  @include mat-icon-override('screen_rotation');
}
.#{$iconPrefix}-screen-share {
  @include mat-icon-override('screen_share');
}
.#{$iconPrefix}-sd-card {
  @include mat-icon-override('sd_card');
}
.#{$iconPrefix}-sd-storage {
  @include mat-icon-override('sd_storage');
}
.#{$iconPrefix}-search {
  @include mat-icon-override('search');
}
.#{$iconPrefix}-security {
  @include mat-icon-override('security');
}
.#{$iconPrefix}-select-all {
  @include mat-icon-override('select_all');
}
.#{$iconPrefix}-send {
  @include mat-icon-override('send');
}
.#{$iconPrefix}-sentiment-dissatisfied {
  @include mat-icon-override('sentiment_dissatisfied');
}
.#{$iconPrefix}-sentiment-neutral {
  @include mat-icon-override('sentiment_neutral');
}
.#{$iconPrefix}-sentiment-satisfied {
  @include mat-icon-override('sentiment_satisfied');
}
.#{$iconPrefix}-sentiment-very-dissatisfied {
  @include mat-icon-override('sentiment_very_dissatisfied');
}
.#{$iconPrefix}-sentiment-very-satisfied {
  @include mat-icon-override('sentiment_very_satisfied');
}
.#{$iconPrefix}-settings {
  @include mat-icon-override('settings');
}
.#{$iconPrefix}-settings-applications {
  @include mat-icon-override('settings_applications');
}
.#{$iconPrefix}-settings-backup-restore {
  @include mat-icon-override('settings_backup_restore');
}
.#{$iconPrefix}-settings-bluetooth {
  @include mat-icon-override('settings_bluetooth');
}
.#{$iconPrefix}-settings-brightness {
  @include mat-icon-override('settings_brightness');
}
.#{$iconPrefix}-settings-cell {
  @include mat-icon-override('settings_cell');
}
.#{$iconPrefix}-settings-ethernet {
  @include mat-icon-override('settings_ethernet');
}
.#{$iconPrefix}-settings-input-antenna {
  @include mat-icon-override('settings_input_antenna');
}
.#{$iconPrefix}-settings-input-component {
  @include mat-icon-override('settings_input_component');
}
.#{$iconPrefix}-settings-input-composite {
  @include mat-icon-override('settings_input_composite');
}
.#{$iconPrefix}-settings-input-hdmi {
  @include mat-icon-override('settings_input_hdmi');
}
.#{$iconPrefix}-settings-input-svideo {
  @include mat-icon-override('settings_input_svideo');
}
.#{$iconPrefix}-settings-overscan {
  @include mat-icon-override('settings_overscan');
}
.#{$iconPrefix}-settings-phone {
  @include mat-icon-override('settings_phone');
}
.#{$iconPrefix}-settings-power {
  @include mat-icon-override('settings_power');
}
.#{$iconPrefix}-settings-remote {
  @include mat-icon-override('settings_remote');
}
.#{$iconPrefix}-settings-system-daydream {
  @include mat-icon-override('settings_system_daydream');
}
.#{$iconPrefix}-settings-voice {
  @include mat-icon-override('settings_voice');
}
.#{$iconPrefix}-share {
  @include mat-icon-override('share');
}
.#{$iconPrefix}-shop {
  @include mat-icon-override('shop');
}
.#{$iconPrefix}-shop-two {
  @include mat-icon-override('shop_two');
}
.#{$iconPrefix}-shopping-basket {
  @include mat-icon-override('shopping_basket');
}
.#{$iconPrefix}-shopping-cart {
  @include mat-icon-override('shopping_cart');
}
.#{$iconPrefix}-short-text {
  @include mat-icon-override('short_text');
}
.#{$iconPrefix}-show-chart {
  @include mat-icon-override('show_chart');
}
.#{$iconPrefix}-shuffle {
  @include mat-icon-override('shuffle');
}
.#{$iconPrefix}-signal-cellular-4-bar {
  @include mat-icon-override('signal_cellular_4_bar');
}
.#{$iconPrefix}-signal-cellular-connected-no-internet-4-bar {
  @include mat-icon-override('signal_cellular_connected_no_internet_4_bar');
}
.#{$iconPrefix}-signal-cellular-no-sim {
  @include mat-icon-override('signal_cellular_no_sim');
}
.#{$iconPrefix}-signal-cellular-null {
  @include mat-icon-override('signal_cellular_null');
}
.#{$iconPrefix}-signal-cellular-off {
  @include mat-icon-override('signal_cellular_off');
}
.#{$iconPrefix}-signal-wifi-4-bar {
  @include mat-icon-override('signal_wifi_4_bar');
}
.#{$iconPrefix}-signal-wifi-4-bar-lock {
  @include mat-icon-override('signal_wifi_4_bar_lock');
}
.#{$iconPrefix}-signal-wifi-off {
  @include mat-icon-override('signal_wifi_off');
}
.#{$iconPrefix}-sim-card {
  @include mat-icon-override('sim_card');
}
.#{$iconPrefix}-sim-card-alert {
  @include mat-icon-override('sim_card_alert');
}
.#{$iconPrefix}-skip-next {
  @include mat-icon-override('skip_next');
}
.#{$iconPrefix}-skip-previous {
  @include mat-icon-override('skip_previous');
}
.#{$iconPrefix}-slideshow {
  @include mat-icon-override('slideshow');
}
.#{$iconPrefix}-slow-motion-video {
  @include mat-icon-override('slow_motion_video');
}
.#{$iconPrefix}-smartphone {
  @include mat-icon-override('smartphone');
}
.#{$iconPrefix}-smoke-free {
  @include mat-icon-override('smoke_free');
}
.#{$iconPrefix}-smoking-rooms {
  @include mat-icon-override('smoking_rooms');
}
.#{$iconPrefix}-sms {
  @include mat-icon-override('sms');
}
.#{$iconPrefix}-sms-failed {
  @include mat-icon-override('sms_failed');
}
.#{$iconPrefix}-snooze {
  @include mat-icon-override('snooze');
}
.#{$iconPrefix}-sort {
  @include mat-icon-override('sort');
}
.#{$iconPrefix}-sort-by-alpha {
  @include mat-icon-override('sort_by_alpha');
}
.#{$iconPrefix}-spa {
  @include mat-icon-override('spa');
}
.#{$iconPrefix}-space-bar {
  @include mat-icon-override('space_bar');
}
.#{$iconPrefix}-speaker {
  @include mat-icon-override('speaker');
}
.#{$iconPrefix}-speaker-group {
  @include mat-icon-override('speaker_group');
}
.#{$iconPrefix}-speaker-notes {
  @include mat-icon-override('speaker_notes');
}
.#{$iconPrefix}-speaker-notes-off {
  @include mat-icon-override('speaker_notes_off');
}
.#{$iconPrefix}-speaker-phone {
  @include mat-icon-override('speaker_phone');
}
.#{$iconPrefix}-spellcheck {
  @include mat-icon-override('spellcheck');
}
.#{$iconPrefix}-star {
  @include mat-icon-override('star');
}
.#{$iconPrefix}-star-border {
  @include mat-icon-override('star_border');
}
.#{$iconPrefix}-star-half {
  @include mat-icon-override('star_half');
}
.#{$iconPrefix}-stars {
  @include mat-icon-override('stars');
}
.#{$iconPrefix}-stay-current-landscape {
  @include mat-icon-override('stay_current_landscape');
}
.#{$iconPrefix}-stay-current-portrait {
  @include mat-icon-override('stay_current_portrait');
}
.#{$iconPrefix}-stay-primary-landscape {
  @include mat-icon-override('stay_primary_landscape');
}
.#{$iconPrefix}-stay-primary-portrait {
  @include mat-icon-override('stay_primary_portrait');
}
.#{$iconPrefix}-stop {
  @include mat-icon-override('stop');
}
.#{$iconPrefix}-stop-screen-share {
  @include mat-icon-override('stop_screen_share');
}
.#{$iconPrefix}-storage {
  @include mat-icon-override('storage');
}
.#{$iconPrefix}-store {
  @include mat-icon-override('store');
}
.#{$iconPrefix}-store-mall-directory {
  @include mat-icon-override('store_mall_directory');
}
.#{$iconPrefix}-straighten {
  @include mat-icon-override('straighten');
}
.#{$iconPrefix}-streetview {
  @include mat-icon-override('streetview');
}
.#{$iconPrefix}-strikethrough-s {
  @include mat-icon-override('strikethrough_s');
}
.#{$iconPrefix}-style {
  @include mat-icon-override('style');
}
.#{$iconPrefix}-subdirectory-arrow-left {
  @include mat-icon-override('subdirectory_arrow_left');
}
.#{$iconPrefix}-subdirectory-arrow-right {
  @include mat-icon-override('subdirectory_arrow_right');
}
.#{$iconPrefix}-subject {
  @include mat-icon-override('subject');
}
.#{$iconPrefix}-subscriptions {
  @include mat-icon-override('subscriptions');
}
.#{$iconPrefix}-subtitles {
  @include mat-icon-override('subtitles');
}
.#{$iconPrefix}-subway {
  @include mat-icon-override('subway');
}
.#{$iconPrefix}-supervisor-account {
  @include mat-icon-override('supervisor_account');
}
.#{$iconPrefix}-surround-sound {
  @include mat-icon-override('surround_sound');
}
.#{$iconPrefix}-swap-calls {
  @include mat-icon-override('swap_calls');
}
.#{$iconPrefix}-swap-horiz {
  @include mat-icon-override('swap_horiz');
}
.#{$iconPrefix}-swap-vert {
  @include mat-icon-override('swap_vert');
}
.#{$iconPrefix}-swap-vertical-circle {
  @include mat-icon-override('swap_vertical_circle');
}
.#{$iconPrefix}-switch-camera {
  @include mat-icon-override('switch_camera');
}
.#{$iconPrefix}-switch-video {
  @include mat-icon-override('switch_video');
}
.#{$iconPrefix}-sync {
  @include mat-icon-override('sync');
}
.#{$iconPrefix}-sync-disabled {
  @include mat-icon-override('sync_disabled');
}
.#{$iconPrefix}-sync-problem {
  @include mat-icon-override('sync_problem');
}
.#{$iconPrefix}-system-update {
  @include mat-icon-override('system_update');
}
.#{$iconPrefix}-system-update-alt {
  @include mat-icon-override('system_update_alt');
}
.#{$iconPrefix}-tab {
  @include mat-icon-override('tab');
}
.#{$iconPrefix}-tab-unselected {
  @include mat-icon-override('tab_unselected');
}
.#{$iconPrefix}-tablet {
  @include mat-icon-override('tablet');
}
.#{$iconPrefix}-tablet-android {
  @include mat-icon-override('tablet_android');
}
.#{$iconPrefix}-tablet-mac {
  @include mat-icon-override('tablet_mac');
}
.#{$iconPrefix}-tag-faces {
  @include mat-icon-override('tag_faces');
}
.#{$iconPrefix}-tap-and-play {
  @include mat-icon-override('tap_and_play');
}
.#{$iconPrefix}-terrain {
  @include mat-icon-override('terrain');
}
.#{$iconPrefix}-text-fields {
  @include mat-icon-override('text_fields');
}
.#{$iconPrefix}-text-format {
  @include mat-icon-override('text_format');
}
.#{$iconPrefix}-textsms {
  @include mat-icon-override('textsms');
}
.#{$iconPrefix}-texture {
  @include mat-icon-override('texture');
}
.#{$iconPrefix}-theaters {
  @include mat-icon-override('theaters');
}
.#{$iconPrefix}-thumb-down {
  @include mat-icon-override('thumb_down');
}
.#{$iconPrefix}-thumb-up {
  @include mat-icon-override('thumb_up');
}
.#{$iconPrefix}-thumbs-up-down {
  @include mat-icon-override('thumbs_up_down');
}
.#{$iconPrefix}-time-to-leave {
  @include mat-icon-override('time_to_leave');
}
.#{$iconPrefix}-timelapse {
  @include mat-icon-override('timelapse');
}
.#{$iconPrefix}-timeline {
  @include mat-icon-override('timeline');
}
.#{$iconPrefix}-timer {
  @include mat-icon-override('timer');
}
.#{$iconPrefix}-timer-10 {
  @include mat-icon-override('timer_10');
}
.#{$iconPrefix}-timer-3 {
  @include mat-icon-override('timer_3');
}
.#{$iconPrefix}-timer-off {
  @include mat-icon-override('timer_off');
}
.#{$iconPrefix}-title {
  @include mat-icon-override('title');
}
.#{$iconPrefix}-toc {
  @include mat-icon-override('toc');
}
.#{$iconPrefix}-today {
  @include mat-icon-override('today');
}
.#{$iconPrefix}-toll {
  @include mat-icon-override('toll');
}
.#{$iconPrefix}-tonality {
  @include mat-icon-override('tonality');
}
.#{$iconPrefix}-touch-app {
  @include mat-icon-override('touch_app');
}
.#{$iconPrefix}-toys {
  @include mat-icon-override('toys');
}
.#{$iconPrefix}-track-changes {
  @include mat-icon-override('track_changes');
}
.#{$iconPrefix}-traffic {
  @include mat-icon-override('traffic');
}
.#{$iconPrefix}-train {
  @include mat-icon-override('train');
}
.#{$iconPrefix}-tram {
  @include mat-icon-override('tram');
}
.#{$iconPrefix}-transfer-within-a-station {
  @include mat-icon-override('transfer_within_a_station');
}
.#{$iconPrefix}-transform {
  @include mat-icon-override('transform');
}
.#{$iconPrefix}-translate {
  @include mat-icon-override('translate');
}
.#{$iconPrefix}-trending-down {
  @include mat-icon-override('trending_down');
}
.#{$iconPrefix}-trending-flat {
  @include mat-icon-override('trending_flat');
}
.#{$iconPrefix}-trending-up {
  @include mat-icon-override('trending_up');
}
.#{$iconPrefix}-tune {
  @include mat-icon-override('tune');
}
.#{$iconPrefix}-turned-in {
  @include mat-icon-override('turned_in');
}
.#{$iconPrefix}-turned-in-not {
  @include mat-icon-override('turned_in_not');
}
.#{$iconPrefix}-tv {
  @include mat-icon-override('tv');
}
.#{$iconPrefix}-unarchive {
  @include mat-icon-override('unarchive');
}
.#{$iconPrefix}-undo {
  @include mat-icon-override('undo');
}
.#{$iconPrefix}-unfold-less {
  @include mat-icon-override('unfold_less');
}
.#{$iconPrefix}-unfold-more {
  @include mat-icon-override('unfold_more');
}
.#{$iconPrefix}-update {
  @include mat-icon-override('update');
}
.#{$iconPrefix}-usb {
  @include mat-icon-override('usb');
}
.#{$iconPrefix}-verified-user {
  @include mat-icon-override('verified_user');
}
.#{$iconPrefix}-vertical-align-bottom {
  @include mat-icon-override('vertical_align_bottom');
}
.#{$iconPrefix}-vertical-align-center {
  @include mat-icon-override('vertical_align_center');
}
.#{$iconPrefix}-vertical-align-top {
  @include mat-icon-override('vertical_align_top');
}
.#{$iconPrefix}-vibration {
  @include mat-icon-override('vibration');
}
.#{$iconPrefix}-video-call {
  @include mat-icon-override('video_call');
}
.#{$iconPrefix}-video-label {
  @include mat-icon-override('video_label');
}
.#{$iconPrefix}-video-library {
  @include mat-icon-override('video_library');
}
.#{$iconPrefix}-videocam {
  @include mat-icon-override('videocam');
}
.#{$iconPrefix}-videocam-off {
  @include mat-icon-override('videocam_off');
}
.#{$iconPrefix}-videogame-asset {
  @include mat-icon-override('videogame_asset');
}
.#{$iconPrefix}-view-agenda {
  @include mat-icon-override('view_agenda');
}
.#{$iconPrefix}-view-array {
  @include mat-icon-override('view_array');
}
.#{$iconPrefix}-view-carousel {
  @include mat-icon-override('view_carousel');
}
.#{$iconPrefix}-view-column {
  @include mat-icon-override('view_column');
}
.#{$iconPrefix}-view-comfy {
  @include mat-icon-override('view_comfy');
}
.#{$iconPrefix}-view-compact {
  @include mat-icon-override('view_compact');
}
.#{$iconPrefix}-view-day {
  @include mat-icon-override('view_day');
}
.#{$iconPrefix}-view-headline {
  @include mat-icon-override('view_headline');
}
.#{$iconPrefix}-view-list {
  @include mat-icon-override('view_list');
}
.#{$iconPrefix}-view-module {
  @include mat-icon-override('view_module');
}
.#{$iconPrefix}-view-quilt {
  @include mat-icon-override('view_quilt');
}
.#{$iconPrefix}-view-stream {
  @include mat-icon-override('view_stream');
}
.#{$iconPrefix}-view-week {
  @include mat-icon-override('view_week');
}
.#{$iconPrefix}-vignette {
  @include mat-icon-override('vignette');
}
.#{$iconPrefix}-visibility {
  @include mat-icon-override('visibility');
}
.#{$iconPrefix}-visibility-off {
  @include mat-icon-override('visibility_off');
}
.#{$iconPrefix}-voice-chat {
  @include mat-icon-override('voice_chat');
}
.#{$iconPrefix}-voicemail {
  @include mat-icon-override('voicemail');
}
.#{$iconPrefix}-volume-down {
  @include mat-icon-override('volume_down');
}
.#{$iconPrefix}-volume-mute {
  @include mat-icon-override('volume_mute');
}
.#{$iconPrefix}-volume-off {
  @include mat-icon-override('volume_off');
}
.#{$iconPrefix}-volume-up {
  @include mat-icon-override('volume_up');
}
.#{$iconPrefix}-vpn-key {
  @include mat-icon-override('vpn_key');
}
.#{$iconPrefix}-vpn-lock {
  @include mat-icon-override('vpn_lock');
}
.#{$iconPrefix}-wallpaper {
  @include mat-icon-override('wallpaper');
}
.#{$iconPrefix}-warning {
  @include mat-icon-override('warning');
}
.#{$iconPrefix}-watch {
  @include mat-icon-override('watch');
}
.#{$iconPrefix}-watch-later {
  @include mat-icon-override('watch_later');
}
.#{$iconPrefix}-wb-auto {
  @include mat-icon-override('wb_auto');
}
.#{$iconPrefix}-wb-cloudy {
  @include mat-icon-override('wb_cloudy');
}
.#{$iconPrefix}-wb-incandescent {
  @include mat-icon-override('wb_incandescent');
}
.#{$iconPrefix}-wb-iridescent {
  @include mat-icon-override('wb_iridescent');
}
.#{$iconPrefix}-wb-sunny {
  @include mat-icon-override('wb_sunny');
}
.#{$iconPrefix}-wc {
  @include mat-icon-override('wc');
}
.#{$iconPrefix}-web {
  @include mat-icon-override('web');
}
.#{$iconPrefix}-web-asset {
  @include mat-icon-override('web_asset');
}
.#{$iconPrefix}-weekend {
  @include mat-icon-override('weekend');
}
.#{$iconPrefix}-whatshot {
  @include mat-icon-override('whatshot');
}
.#{$iconPrefix}-widgets {
  @include mat-icon-override('widgets');
}
.#{$iconPrefix}-wifi {
  @include mat-icon-override('wifi');
}
.#{$iconPrefix}-wifi-lock {
  @include mat-icon-override('wifi_lock');
}
.#{$iconPrefix}-wifi-tethering {
  @include mat-icon-override('wifi_tethering');
}
.#{$iconPrefix}-work {
  @include mat-icon-override('work');
}
.#{$iconPrefix}-wrap-text {
  @include mat-icon-override('wrap_text');
}
.#{$iconPrefix}-youtube-searched-for {
  @include mat-icon-override('youtube_searched_for');
}
.#{$iconPrefix}-zoom-in {
  @include mat-icon-override('zoom_in');
}
.#{$iconPrefix}-zoom-out {
  @include mat-icon-override('zoom_out');
}
.#{$iconPrefix}-zoom-out-map {
  @include mat-icon-override('zoom_out_map');
}
