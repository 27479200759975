.layout-topbar-white {
  --topbar-bg: #ffffff;
  --topbar-start-bg: #ffffff;
  --topbar-box-shadow: 0 24px 64px -2px rgb(0 0 0 / 2%),
    0 6px 16px -2px rgb(0 0 0 / 6%), 0 2px 6px -2px rgb(0 0 0 / 8%);
  --topbar-menu-button-bg: var(--primary-color);
  --topbar-menu-button-hover-bg: var(--primary-600);
  --topbar-menu-button-text-color: var(--primary-color-text);
  --topbar-item-text-color: #616161;
  --topbar-item-hover-bg: rgba(0, 0, 0, 0.12);
}
